/** Libraries */
import { CheckIcon } from "@heroicons/react/solid";

/** Services */
import { checkout } from "@services/rest/user";

export type ISubscriptionProps = {
  amount: string;
  billableTime: string;
  numberOfContacts?: string;
  type: string;
  priceId: string;
};

const SubscriptionType = ({
  amount,
  billableTime,
  numberOfContacts,
  type,
  priceId,
}: ISubscriptionProps) => {
  const upgrade = async () => {
    const { data } = await checkout(priceId);
    window.location = data.args.url;
  };

  return (
    <section
      aria-labelledby="subscription-title"
      className="lg:col-start-3 lg:col-span-1"
    >
      <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
        <h2
          id="subscription-title"
          className="text-lg font-medium text-gray-900"
        >
          {billableTime}
        </h2>
        <div className="flex">
          <div>
            <h1 className="text-3xl font-medium text-gray-900">{amount}</h1>
          </div>
          <div className="flex items-center">
            <h1 className="font-medium text-gray-900 ">/{type}</h1>
          </div>
        </div>

        <div className="mt-6 flow-root">
          <div className="relative pb-8">
            <div className="relative flex space-x-3">
              <div>
                <span
                  className={
                    "bg-green-500 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                  }
                >
                  <CheckIcon
                    className="w-5 h-5 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                <div>
                  <p className="text-gray-700">
                    {numberOfContacts
                      ? `Add contacts up to ${numberOfContacts}`
                      : `Add unlimited number of contacts`}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 flex flex-col justify-stretch space-y-4">
          <button
            type="button"
            onClick={upgrade}
            className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primaryColor hover:bg-primaryColor-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primaryColor-dark"
          >
            Upgrade
          </button>
        </div>
      </div>
    </section>
  );
};

export default SubscriptionType;
