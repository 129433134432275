/** Libraries */
import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';

/** Services */
import {
  deleteCategory,
  listCategories,
} from '@services/rest/user';

/** Types */
import { ICategory } from '@internal-types/CategoryTypes';

/** Store */
import { useStores } from '@hooks/useStores';

const CategoryList = () => {
  const { categoryStore } = useStores();
  const category = useRef<HTMLInputElement>(null);
  const categoryForm = useRef<HTMLFormElement>(null);

  useEffect(() => {
    getListCategories();
  }, []);

  const getListCategories = async () => {
    try {
      const { data: { args } } = await listCategories();
      categoryStore.setCategoryList(args);
    } catch (error) {
      console.log('Failed getting all the categories', error);
    }
  };

  const removeCategory = async (id: string) => {
    try {
      const { data: { args } } = await deleteCategory(id);
      if (args) {
        categoryStore.deleteCategory(id);
      }
    } catch (error) {
      console.log('Failed deleting category', error);
    }
  };

  const handleAddCategory = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
  };

  return (
    <div className="mt-8">
      <div
        className="mt-1 space-y-1"
        role="group"
        aria-labelledby="teams-headline"
      >
        <form
          className="mt-5 flex items-center space-x-4 sm:justify-end"
          onSubmit={handleAddCategory}
          ref={categoryForm}
        >
          <div className="w-full sm:max-w-xs">
            <label htmlFor="category" className="sr-only">
              Category
            </label>
            <input
              ref={category}
              type="text"
              name="category"
              id="category"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Enter new category"
            />
          </div>
          <button
            type="submit"
            className="inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-primaryColor hover:bg-primaryColor-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Add
          </button>
        </form>
        <div className="mt-6">
          <dl className="divide-y divide-gray-200">
            {categoryStore?.categoryList.map((category: ICategory) => (
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
                <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <span className="flex-grow">{category.name}</span>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default observer(CategoryList);
