/** Libraries */
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Form, Input, Button } from "antd";
import { observer } from "mobx-react-lite";

/** Store */
import { useStores } from "@hooks/useStores";

const messageBody = `Hi!%0D%0A
%0D%0A
I am using My Vital Few&#8482; to keep in touch with my most important personal and professional contacts.%0D%0A
I think you might find it useful, too. Visit https://www.myvitalfew.com to get a free account.%0D%0A
%0D%0A
Thanks!`;

const ShareModal = () => {
  const [form] = Form.useForm();
  const { userStore } = useStores();

  const handleOnFinish = () => {
    userStore.toggleShareModal(false);

    window.location.href = `mailto:${form.getFieldValue(
      "email"
    )}?subject=Share My Vital Few&body=${messageBody}`;

    form.resetFields();
  };

  const handleCancel = () => {
    form.resetFields();
    userStore.toggleShareModal(false);
  };

  return (
    <Transition.Root show={userStore.showShareModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={() => handleCancel()}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => userStore.toggleShareModal(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="pt-5">
                <Form
                  form={form}
                  name="refer"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  layout={"vertical"}
                  autoComplete="off"
                  onFinish={handleOnFinish}
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Required field.",
                        type: "email",
                      },
                    ]}
                  >
                    <Input placeholder="example@email.com" />
                  </Form.Item>
                  <h3>Message:</h3>
                  <p className="border-2 rounded-sm p-2">
                    Hi!
                    <br />
                    <br />
                    I am using My Vital Few&#8482; to keep in touch with my most
                    important personal and professional contacts. I think you
                    might find it useful, too. Visit https://www.myvitalfew.com
                    to get a free account.
                    <br />
                    <br />
                    Thanks!
                  </p>
                  <Form.Item>
                    <div className="flex space-x-2 justify-center lg:justify-end">
                      <div className="md:order-first">
                        <Button
                          htmlType="button"
                          onClick={handleCancel}
                          size="large"
                          style={{
                            paddingRight: "28px",
                            paddingLeft: "28px",
                            borderRadius: "20px",
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="md:ml-2 md:order-last">
                        <Button
                          type="primary"
                          htmlType="submit"
                          size="large"
                          style={{
                            paddingRight: "32px",
                            paddingLeft: "32px",
                            borderRadius: "20px",
                          }}
                        >
                          Share
                        </Button>
                      </div>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default observer(ShareModal);
