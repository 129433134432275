/** Libraries */
import { useState, useEffect } from "react";
import { Form, Input, Button } from "antd";
import { toast } from "react-toastify";

/** Store */
import { useStores } from "@hooks/useStores";
import { flowResult } from "mobx";

const EmailSettingsInput = () => {
  const { userStore } = useStores();
  const [showInput, setShowInput] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    function setFormInitialValue() {
      form.setFieldsValue({
        email: userStore.contactInfo.email,
      });
    }

    setFormInitialValue();
  }, []);

  const handleOnFinish = async (values: { email: string }) => {
    try {
      const result = await flowResult(
        userStore.updateUserInfo({ contactInfo: { ...values } })
      );

      if (result) {
        toast.success("Successfully updated your personal information.");
      } else {
        toast.error("Something went wrong. Please try again later");
      }
    } catch (e) {
      console.log("Error updating email:", e);
      toast.error("Error occured. Please try again later.");
    }

    setShowInput(false);
  };

  const handleOnCancel = () => {
    form.setFieldsValue({
      email: userStore.contactInfo.email,
    });
    setShowInput(false);
  };

  return (
    <div className="py-4 sm:py-5 sm:gap-4 sm:pt-5 flex">
      <div className="flex w-2/6 shrink">
        <p className="text-sm font-medium text-gray-500">Email</p>
      </div>
      <div className="flex-1 flex">
        <div className="flex-1">
          <Form
            form={form}
            name="basic"
            onFinish={handleOnFinish}
            autoComplete="off"
            layout={"inline"}
          >
            <div className="flex-1 flex">
              {showInput ? (
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input a valid email address.",
                    },
                  ]}
                >
                  <Input
                    type="email"
                    autoFocus
                    style={{ padding: 0 }}
                  />
                </Form.Item>
              ) : (
                <span className="flex-grow">{userStore.contactInfo.email}</span>
              )}
            </div>
            <div className="flex-1 flex justify-end">
              {showInput ? (
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      paddingRight: "24px",
                      paddingLeft: "24px",
                      borderRadius: "20px",
                    }}
                  >
                    Save
                  </Button>
                  <span className="text-gray-300 px-1" aria-hidden="true">
                    |
                  </span>
                  <Button
                    htmlType="button"
                    onClick={handleOnCancel}
                    style={{
                      paddingRight: "24px",
                      paddingLeft: "24px",
                      borderRadius: "20px",
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      setShowInput(true);
                    }}
                    className="bg-white rounded-md font-medium text-primaryColor hover:text-primaryColor-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primaryColor-dark"
                  >
                    Update
                  </button>
                </>
              )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default EmailSettingsInput;
