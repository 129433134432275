import Axios from "axios";

/** Config */
import { DOMAIN } from "@/config";

/** types */
import { OnboardingStatusTypes } from "@internal-types/OnboardingTypes";

export const loadCurentUser = (data: any) => {
  const token = localStorage.getItem("token");
  const options: any = {
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${DOMAIN!}/api/v1/users/loadcurrent`,
    data: {
      user: data,
    },
  };
  return Axios(options);
};

export const saveCurentUser = (data: any) => {
  const token = localStorage.getItem("token");
  const options: any = {
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${DOMAIN!}/api/v1/users/savecurrent`,
    data: {
      user: data,
    },
  };
  return Axios(options);
};

export const manageBilling = () => {
  const token = localStorage.getItem("token");
  const options: any = {
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${DOMAIN!}/api/v1/stripe/create-customer-portal-session`,
  };
  return Axios(options);
};

export const checkout = (priceId: string) => {
  const token = localStorage.getItem("token");
  const options: any = {
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${DOMAIN!}/api/v1/stripe/create-checkout-session`,
    data: {
      priceId,
    },
  };
  return Axios(options);
};

export const activateUser = () => {
  return Axios.post(`${DOMAIN!}/api/v1/users/activate`);
};

export const listCategories = () => {
  const token = localStorage.getItem("token");
  const options: any = {
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${DOMAIN!}/api/v1/users/category/list/all`,
  };
  return Axios(options);
};

export const deleteCategory = (id: string) => {
  const token = localStorage.getItem("token");
  const options: any = {
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${DOMAIN!}/api/v1/users/category/delete/${id}`,
  };
  return Axios(options);
};

export const saveStep = (data: { step: OnboardingStatusTypes }) => {
  const token = localStorage.getItem("token");
  const options: any = {
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${DOMAIN!}/api/v1/users/savestep`,
    data: data,
  };

  return Axios(options);
};

export const updateCategories = (data: { categories: [] }) => {
  const token = localStorage.getItem("token");

  const options: any = {
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${DOMAIN!}/api/v1/users/categories/update`,
    data: data,
  };

  return Axios(options);
};
