/** Libraries */
import { useAuth0 } from "@auth0/auth0-react";
import { Timeline } from "antd";

/** Assets */
import SplashContactDetails from "@assets/splash-contact-details.png";
import MVFLogo from "@assets/mvf-logo.png";
import MVFVideo from "@assets/add-first-contact-guide.mp4";
import People from "@assets/people.jpg";

/** Organisms */
import LoginSignUpPage from "@organisms/LoginSignUp";

const Splash = () => {
  const { loginWithRedirect } = useAuth0();

  const handleRedirectAuth0 = () => {
    loginWithRedirect();
  };

  return (
    <>
      <section className="flex flex-col items-left justify-center bg-fixed bg-center bg-cover bg-onboarding pt-10">
        <div className="flex-1 md:flex">
          <div className="md:order-last flex-1 flex items-center justify-center p-10 md:py-2 md:px-10">
            <div className="flex max-w-4xl mx-auto">
              <div className="flex-1 content-center">
                <div className="bg-white shadow-2xl rounded-2xl py-2 px-10">
                  <h1 className="text-3xl self-center">
                    <span className="text-4xl text-primaryColor">
                      My Vital Few&#8482;
                    </span>{" "}
                    works with LinkedIn to automate your networking campaign
                    when searching for a job.
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="flex flex-col items-center justify-center bg-fixed bg-center bg-cover bg-onboarding pt-10">
        <div className="flex-1 md:flex">
          <div className="md:order-last flex-1 flex items-center justify-center p-10 md:py-2 md:px-10">
            <LoginSignUpPage />
          </div>
          <div className="md:order-first flex-1 flex items-center justify-center py-2 px-10">
            <div>
              <h1 className="text-3xl">
                Unlike spreadsheets, Google Sheets, and Post-it notes, My Vital
                Few&#8482; ensures you follow up and stay in touch with the people who
                can help you the most with your search.
                <br />
                &nbsp;
                <br />
                <span className="text-4xl text-primaryColor">
                  My Vital Few&#8482;:
                </span>{" "}
                <br />
              </h1>
              <Timeline>
                <Timeline.Item>
                  <span className="text-lg">
                    Builds your networking campaign
                  </span>
                </Timeline.Item>
                <Timeline.Item>
                  <span className="text-lg">
                    Sends you email reminders when it is time to reach out
                  </span>
                </Timeline.Item>
                <Timeline.Item>
                  <span className="text-lg">
                    Ensures a regular networking cadence
                  </span>
                </Timeline.Item>
                <Timeline.Item>
                  <span className="text-lg">
                    Tracks important conversations
                  </span>
                </Timeline.Item>
                <Timeline.Item>
                  <span className="text-lg">
                    Maintains connections after you land a new job
                  </span>
                </Timeline.Item>
              </Timeline>
            </div>
          </div>
        </div>
        <div className="hidden md:flex-1 md:flex">
          <div className="flex-1 items-center justify-center p-10">
            <img
              className="py-4"
              src={SplashContactDetails}
              alt="Contact Details"
            />
          </div>
        </div>
      </section>
      <section className="flex flex-col items-center justify-center bg-fixed bg-center bg-cover bg-onboarding">
        <div className="flex-1 md:flex">
          <div className="flex-1 flex items-center justify-center py-2 px-10">
            <div className="flex-1 items-center justify-center py-2 px-10">
              <h1 className="text-xl pb-2">
                <span className="text-4xl text-primaryColor">
                  My Vital Few&#8482; is effortless:
                </span>
              </h1>
              <Timeline>
                <Timeline.Item>
                  <span className="text-xl">
                    <u>With one click</u>, easily add connections from LinkedIn,
                    Facebook, Twitter, alumni directories, etc.
                  </span>
                </Timeline.Item>
                <Timeline.Item>
                  <span className="text-xl">
                    Put your contacts in <u>meaningful categories</u> that you
                    can create
                  </span>
                </Timeline.Item>
                <Timeline.Item>
                  <span className="text-xl">
                    <u>Determine how frequently</u> you want to reach out to
                    each connection
                  </span>
                </Timeline.Item>
                <Timeline.Item>
                  <span className="text-xl">
                    <u>Receive email reminders</u> from My Vital Few&#8482; when it is
                    time to make contact; set it and forget it
                  </span>
                </Timeline.Item>
                <Timeline.Item>
                  <span className="text-xl">
                    <u>Capture notes</u> after each interaction that you can
                    refer to later
                  </span>
                </Timeline.Item>
              </Timeline>
            </div>
            <div className="flex max-w-xl mx-auto">
              <div className="flex-1 content-center">
                <div className="bg-white shadow-2xl rounded-2xl">
                  <h1 className="text-3xl text-center p-10">
                    According to HubSpot, 85% of all jobs are found by
                    networking and CNBC reports that 70% of jobs are never
                    published publicly. My Vital Few&#8482; is how you hear about open
                    jobs you don’t know are available
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* What follows below is the proposed landing page, commented out for now */}
      {/* <>
        <section
          className="min-h-screen pt-5 md:pt-8 pb-6"
          style={{
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, rgba(3, 30, 95, 0.57), rgba(3, 30, 95, 0.57)), url(${People})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="container mx-auto px-6">
            <div className="bg-white flex items-center justify-between px-8 py-2 rounded-full">
              <div>
                <img className="w-36 sm:w-52" src={MVFLogo} alt="mvf-logo" />
              </div>
              <div>
                <a href="#">
                  <img
                    className="w-6 h-6 object-contain rounded-full"
                    src="https://www.seekpng.com/png/detail/148-1483528_facebook-instagram-youtube-facebook-icon-white-png-transparent.png"
                    alt="facebook-logo"
                  />
                </a>
              </div>
            </div>
            <div className="mt-8 md:mt-12 pb-6 mb-2 max-w-3xl mx-auto px-0">
              <h1 className="text-white text-2xl md:text-3xl text-center mb-0 leading-7">
                My Vital Few works with LinkedIn to automate your networking
                campaign when searching for a job.
              </h1>
            </div>
            <LoginSignUpPage />

            <div className="mt-8 max-w-lg mx-auto">
              <p className="mb-0 text-base text-white text-center">
                Unlike spreadsheets, Google Sheets, and Post-it notes, My Vital
                Few ensures you follow up and stay in touch with the people who
                can help you the most with your search.
              </p>
            </div>
          </div>
        </section>

        <section className="w-full h-4/6 bg-authContainerBg">
          <div className="container py-14 md:py-32 px-6 md:px-0 mx-auto grid grid-cols-1 md:grid-cols-2 gap-y-5 md:gap-5 items-center">
            <div>
              <img
                className="object-cover w-full"
                src={SplashContactDetails}
                alt="splash-contact"
              />
            </div>
            <div>
              <video src={MVFVideo} width="100%" height="100%" controls></video>
            </div>
          </div>
        </section>

        <section
          className="w-full h-4/6 pt-12 pb-28"
          style={{
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, rgba(3, 30, 95, 0.57), rgba(3, 30, 95, 0.57)), url(${People})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="container mx-auto px-5 md:px-0">
            <div className="mb-14">
              <h1 className="text-center text-3xl mb-2 text-white">
                Our executive team
              </h1>
              <p className="text-center text-md text-white">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Veniam, sunt?
              </p>
            </div>
            <div className="h-auto grid grid-cols-1 md:grid-cols-2 gap-10 mt-5">
              <div className="w-full px-6 md:px-12 py-8 md:py-10 bg-white rounded-2xl border-l-8 border-b-8 border-darkBlueBorder">
                <div className="flex mb-4">
                  <img
                    className="h-24 w-24 rounded-full"
                    src="https://st3.depositphotos.com/14903220/37662/v/450/depositphotos_376629516-stock-illustration-avatar-men-graphic-sign-profile.jpg"
                    alt="sample-image"
                  />
                  <div className="ml-3 flex flex-col items-start justify-center">
                    <h2 className="mb-0 text-lg font-semibold text-landingPageAccent">
                      Bill Fitzgerald
                    </h2>
                    <p className="leading-3">
                      Co-Founder & Product/Sales leader
                    </p>
                  </div>
                </div>
                <div>
                  <p className="mb-0">
                    Bill is the Co-Founder and Product/Sales leader for
                    &#8482;My Vital View. Bill is also the CEO and founder of
                    Fitz Drake Search, Inc., an executive-retained search firm
                    located in northern Virginia. Bill has worked with thousands
                    of job seekers, and My Vital Few makes the job search
                    process easier.
                  </p>
                </div>
              </div>

              <div className="w-full px-6 md:px-12 py-8 md:py-10 bg-white rounded-2xl border-l-8 border-b-8 border-darkBlueBorder">
                <div className="flex mb-4">
                  <img
                    className="h-24 w-24 rounded-full"
                    src="https://st3.depositphotos.com/14903220/37662/v/450/depositphotos_376629516-stock-illustration-avatar-men-graphic-sign-profile.jpg"
                    alt="sample-image"
                  />
                  <div className="ml-3 flex flex-col items-start justify-center">
                    <h2 className="mb-0 text-lg font-semibold text-landingPageAccent">
                      Chuck Shotton
                    </h2>
                    <p className="leading-3">Co-Founder and CTO </p>
                  </div>
                </div>
                <div>
                  <p className="mb-0">
                    Chuck is the Co-founder and CTO of My Vital Few. Chuck is
                    also the President of Confluent systems, lLC. Chuck and his
                    team are creating the next-generation platform for business
                    process automation using their propriety low-code/no-code
                    platform.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="w-full pt-12 pb-24 bg-authContainerBg relative">
          <div
            className="bg-landingPageAccent absolute -top-20 m-auto left-0 right-0
                     max-w-3xl text-white rounded-md"
          >
            <p className="mb-0 px-5 md:px-12 py-7 text-base md:text-lg text-center">
              According to HubSpot, 85% of all jobs are found by networking and
              CNBC reports that 70% of jobs are never published publicly. My
              Vital Few is how you hear about open jobs you don’t know are
              available
            </p>
          </div>
        </section>

        <section className="w-full bg-authContainerBg">
          <div className="container mx-auto px-5 md:px-0">
            <div className="h-auto grid grid-cols-1 md:grid-cols-2 gap-10 pb-14">
              <div className="w-full px-10 py-10 bg-white rounded-2xl border-l-8 border-b-8 border-darkBlueBorder">
                <div className="flex h-8 mb-5 text-lg md:text-2xl font-medium text-landingPageAccent">
                  &#8482;My Vital few
                </div>
                <ul className="list-disc space-y-3">
                  <li>Builds your networking campaign</li>
                  <li>
                    Sends you email reminders when it is time to reach out
                  </li>
                  <li>Ensures a regular networking cadence</li>
                  <li>Tracks important conversations</li>
                  <li>Maintains connections after you land a new job</li>
                </ul>
              </div>

              <div className="w-full px-10 py-10 bg-white rounded-2xl border-l-8 border-b-8 border-darkBlueBorder">
                <div className="flex h-8 mb-5 text-lg md:text-2xl font-medium text-landingPageAccent">
                  &#8482;My Vital few is effortless
                </div>
                <ul className="list-disc space-y-3">
                  <li>
                    With one click, easily add connections from LinkedIn,
                    Facebook, Twiiter, alumni directories, etc.
                  </li>
                  <li>
                    Put your contacts in meaningful categories that you can
                    create
                  </li>
                  <li>
                    Determine how frequently you want to reach out to each
                    connection
                  </li>
                  <li>
                    Receive email reminders from My Vital Few when it is time to
                    make contact: set it and forget it
                  </li>
                  <li>
                    Capture notes after each interaction that you can refer to
                    later
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-landingPageAccent py-5 w-full flex items-center justify-center">
          <p className="mb-0 text-white text-base">
            Copyright &#8482;My Vital Few. All rights reserved.
          </p>
        </section>
      </> */}
    </>
  );
};

export default Splash;