import { makeAutoObservable } from 'mobx';
import { RootStore } from '@store/RootStore';
import _ from 'lodash';

const initialSteps = [
  { id: 1, name: 'Basic Information', description: "Tell us who you are", status: 'current' },
  { id: 2, name: 'Install Bookmarklet', description: "Get ready to add connections", status: 'upcoming' },
  { id: 3, name: 'Add your first contact', description: "Easier than you can imagine", status: 'upcoming' },
];

export interface IOnboardingStore {
  onboardingSteps: IOnboardingSteps[];
}

export type IOnboardingSteps = {
  id: number;
  name: string;
  status: string;
};

export class OnboardingStore implements IOnboardingStore {
  private rootStore: RootStore;

  onboardingSteps = initialSteps;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  setOnBoardingStatus(id: number, status: string) {
    const index = _.findIndex(this.onboardingSteps, (step) => step.id === id);
    if (index > -1) {
      this.onboardingSteps[index].status = status;
    }
  }
}
