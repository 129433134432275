const TOKEN_NAME = process.env.REACT_APP_TOKEN ? process.env.REACT_APP_TOKEN : "token";
const DOMAIN = process.env.REACT_APP_DOMAIN;
const BASIC_MONTHLY_PRICE= process.env.REACT_APP_BASIC_MONTHLY_PRICE ? process.env.REACT_APP_BASIC_MONTHLY_PRICE : "$4.99";
const BASIC_MONTHLY_PRICE_SKU = process.env.REACT_APP_BASIC_MONTHLY_PRICE_SKU ? process.env.REACT_APP_BASIC_MONTHLY_PRICE_SKU : "price_1KHfx4JwbYHOeBQ99Sgh4alA";
const BASIC_ANNUALLY_PRICE = process.env.REACT_APP_BASIC_ANNUALLY_PRICE ? process.env.REACT_APP_BASIC_ANNUALLY_PRICE : "$49.95";
const BASIC_ANNUALLY_PRICE_SKU = process.env.REACT_APP_BASIC_ANNUALLY_PRICE_SKU ? process.env.REACT_APP_BASIC_ANNUALLY_PRICE_SKU : "price_1KHfx4JwbYHOeBQ9aahDusta";

console.log(process.env.REACT_APP_BASIC_MONTHLY_PRICE)
export {
	TOKEN_NAME,
	DOMAIN,
	BASIC_MONTHLY_PRICE,
	BASIC_MONTHLY_PRICE_SKU,
	BASIC_ANNUALLY_PRICE,
	BASIC_ANNUALLY_PRICE_SKU
};
