/** Libraries */
import React from "react";
import { EmojiSadIcon } from "@heroicons/react/outline";
import { useAuth0 } from "@auth0/auth0-react";

const NonExistingUser = () => {
  const { logout } = useAuth0();

  const handleRedirectAuth0 = () => {
    localStorage.clear();
    logout({returnTo: `${window.location.origin}/reroute`});
  };

  return (
    <main className="bg-onboarding bg-cover h-screen flex items-center justify-center">
      <div className="flex h-1/4 w-1/4 max-w-4xl mx-auto">
        <div className="flex-1 content-center">
          <div className="bg-white shadow-2xl rounded-2xl p-10 space-y-3">
            <div className="flex flex-col md:flex-row justify-center items-center">
              <EmojiSadIcon
                className="h-20 w-20 text-yellow-500"
                aria-hidden="true"
              />
            </div>
            <div className="pb-5">
              <h1 className="text-2xl text-center">User is not existing.</h1>
            </div>
            <div className="px-12 pb-3 justify-center">
              <button
                className="py-2 w-full rounded-full bg-primaryColor text-white truncate"
                onClick={handleRedirectAuth0}
              >
                Sign up
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default NonExistingUser;
