/** Libraries */
import { action, makeAutoObservable, flow } from "mobx";
import { RootStore } from "@store/RootStore";

/** Rest */
import { saveCurentUser } from "@services/rest/user";

/** Types */
import {
  IUserDetail,
  IContactInfo,
  IMetadata,
} from "@internal-types/UserTypes";

export interface IUserStore {
  contactInfo?: IContactInfo;
  firstName?: string;
  fullName?: string;
  lastName?: string;
  metadata?: IMetadata;
  status?: string;
  username?: string;
  showUpgradeBanner: boolean;
  showShareModal: boolean;
}

export class UserStore implements IUserStore {
  private rootStore: RootStore;

  contactInfo: IContactInfo = {};
  firstName = "";
  fullName = "";
  lastName = "";
  metadata: IMetadata = { onboardingStatus: false, step: 1 };
  status = "";
  username = "";
  showUpgradeBanner = false;
  showShareModal = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {
      updateUserInfo: flow,
      setUserDetail: action,
      update: action,
      toggleOnboardingStatus: action,
      toggleUpgradeBanner: action,
      toggleShareModal: action,
    });
  }

  setUserDetail(userDetail: IUserDetail) {
    this.contactInfo = userDetail.contactInfo;
    this.firstName = userDetail.firstName;
    this.fullName = userDetail.fullName;
    this.lastName = userDetail.lastName;
    this.metadata = userDetail.metadata;
    this.status = userDetail.status;
    this.username = userDetail.username || "";
  }

  update(value: string) {
    this.fullName = value;
  }

  toggleOnboardingStatus() {
    this.metadata.onboardingStatus = !this.metadata.onboardingStatus;
  }

  toggleUpgradeBanner(toggle: boolean) {
    this.showUpgradeBanner = toggle;
  }

  toggleShareModal(toggle: boolean) {
    this.showShareModal = toggle;
  }

  * updateUserInfo(value: any) {
    try {
      const { data } = yield saveCurentUser(value);

      if (data && data.args) {
        if (value.contactInfo) {
          this.contactInfo = value.contactInfo
        } else {
          this.firstName = value.firstName;
          this.lastName = value.lastName;
          this.fullName = `${value.firstName} ${value.lastName}`;
        }

        return true;
      }
    } catch (e) {
      console.log("Error updating user info");
      return false;
    }
  }
}
