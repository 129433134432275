/** Libraries */
import { useAuth0 } from "@auth0/auth0-react";

/** Services */
import { manageBilling } from "@services/rest/user";

/** Atoms */
import EmailSettingsInput from "@atoms/EmailSettingsInput";
import NameSettingsInput from "@atoms/NameSettingsInput";

const GeneralSettings = () => {
  const { user } = useAuth0();

  const goToBilling = async () => {
    const { data } = await manageBilling();
    window.location = data.args.url;
  };

  return (
    <div className="mt-10 divide-y divide-gray-200">
      <div className="flex space-y-1 space-x-4 justify-between">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Profile
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            This information will be displayed publicly so be careful what you
            share.
          </p>
        </div>
        <div>
          <button
            onClick={goToBilling}
            type="button"
            className="truncate inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-primaryColor hover:bg-primaryColor-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primaryColor-dark"
          >
            Manage billing
          </button>
        </div>
      </div>
      <div className="mt-6">
        <dl className="divide-y divide-gray-200">
          <NameSettingsInput />
          <EmailSettingsInput />
        </dl>
      </div>
    </div>
  );
};

export default GeneralSettings;
