/** Library */
import { makeAutoObservable } from 'mobx';
import { RootStore } from '@store/RootStore';
import _ from 'lodash';

/** types */
import { ICategory } from '@internal-types/CategoryTypes';

export interface ICategoryStore {
  categoryList: ICategory[];
  categories: Array<string>;
  showCategoryModal: boolean;
}

export class CategoryStore implements ICategoryStore {
  private rootStore: RootStore;

  categoryList: ICategory[] = [];
  categories = ["Friends", "Mentor", "Co-Worker", "Manager", "Team Leader", "Admin", "Talent Acquisition", "Others"];
  showCategoryModal = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  setCategoryList(categories: ICategory[]) {
    this.categoryList = categories;
  }

  addCategory(category: ICategory) {
    this.categoryList.push(category);
  }

  deleteCategory(id: string) {
    _.remove(this.categoryList, (category) => category.id === id);
  }

  toggleCategoryModal(toggle: boolean) {
    this.showCategoryModal = toggle;
  }

  setCategories(categories: Array<string>) {
    this.categories = categories;
  }
}
