/** Libraries */
import { observer } from "mobx-react-lite";
import YouTube from 'react-youtube';

/** Store */
import { useStores } from "@hooks/useStores";

/** Services */
import { saveStep } from "@services/rest/user";

/** Constants */
import {
  CURRENT_STEPS,
  addFirstContactSteps,
} from "@constants/onboardingSteps";

/** Assets */
import AddFirstContactGuide from "@assets/add-first-contact-guide.mp4";

const AddFirstContact = () => {
  const { userStore, onboardingStore } = useStores();

  const handleNextStep = async () => {
    const step = {
      step: 4,
    };

    try {
      const response = await saveStep({ step });

      if (response.data.success) {
        userStore.toggleOnboardingStatus();
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const handleGoBack = async () => {
    const step = {
      step: 2,
    };

    try {
      const response = await saveStep({ step });

      if (response.data.success) {
        onboardingStore.setOnBoardingStatus(
          CURRENT_STEPS.BOOKMARKLET_INSTALL,
          "current"
        );
        onboardingStore.setOnBoardingStatus(
          CURRENT_STEPS.ADD_FIRST_CONTACT,
          "upcoming"
        );
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  return (
    <main>
      <div className="max-w-2xl mx-auto px-5">
        <div className="flex px-4 py-5 sm:px-0">
          <div className="bg-white shadow-2xl overflow-hidden flex-1 px-10">
            <div className="space-y-4 sm:space-y-2">
              <div className="flex justify-between align-middle pt-3">
                <h3 className="onboarding-title">Add First Contact</h3>
                <button className="text-primaryColor" onClick={handleNextStep}>
                  You may skip this step {">"}
                </button>
              </div>
              <div className="flex-1 flow-root">
                <div className="flex flex-col items-center pb-5">
                <YouTube
                  videoId="-qxLTZ_vdLc"
                  title="Add First Contact Guide"
                  opts={{
                    width:"500",
                    height:"300"
                  }} />
                  <p className="text-center pt-1">Watch video for more info</p>
                </div>
                <ul role="list" className="-mb-6">
                  {addFirstContactSteps.map((event) => (
                    <li key={event.id}>
                      <div className="relative pb-3">
                        <div className="relative flex space-x-3">
                          <div>
                            <span className="flex-shrink flex items-center justify-center-0 w-15 h-15 steps-bg">
                              <span className="flex items-center justify-center h-8 w-8 steps shadow-xl">
                                {event.id}
                              </span>
                            </span>
                          </div>
                          <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                            <div>
                              <p className="text-sm text-gray-500">
                                {event.content}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="flex justify-center m-2">
                  <p className="text-gray-500">That's all there is to it!</p>
                </div>
              </div>
            </div>
            <div className="py-2">
              <div className="flex flex-col md:flex-row md:justify-center">
                <button
                  type="button"
                  onClick={handleNextStep}
                  className="m-2 inline-flex justify-center lg:order-last button-action"
                >
                  Next
                </button>
                <button
                  type="button"
                  onClick={handleGoBack}
                  className="m-2 lg:order-first button button-cancel"
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default observer(AddFirstContact);
