/** Libraries */
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router";

/** Organisms */
import GeneralSettings from "@organisms/GeneralSettings";
import CategoryList from "@organisms/CategoryList";

const tabs = [{ name: "General", current: true }];

const Settings = observer(() => {
  const [currentTab, setCurrentTab] = useState("General");
  const history = useHistory();

  const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(" ");
  };

  const changeTab = (tabName: string) => {
    setCurrentTab(tabName);
  };

  const SettingsMode = () => {
    if (currentTab === "General") {
      return <GeneralSettings />;
    } else {
      return <CategoryList />;
    }
  };

  const handleGoBack = () => {
    history.go(-1);
  };

  return (
    <main className="flex-1 overflow-y-auto focus:outline-none">
      <div className="relative max-w-4xl mx-auto md:px-8 xl:px-0">
        <div className="pt-10 pb-16">
          <div className="flex justify-between px-4 sm:px-6 md:px-0">
            <h1 className="text-3xl font-extrabold text-gray-900"> Profile </h1>
            <button
              onClick={handleGoBack}
              className="px-6 py-2 bg-primaryColor text-white text-base font-medium rounded-full hover:bg-primaryColor-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primaryColor-dark"
            >
              Back
            </button>
          </div>
          <div className="px-4 sm:px-6 md:px-0">
            <div className="py-6">
              {/* Tabs */}
              <div className="lg:hidden">
                <label htmlFor="selected-tab" className="sr-only">
                  Select a tab
                </label>
                <select
                  id="selected-tab"
                  name="selected-tab"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primaryColor focus:border-primaryColor sm:text-sm rounded-md"
                  defaultValue={currentTab}
                  onChange={(e) => changeTab(e.target.value)}
                >
                  {tabs.map((tab) => (
                    <option key={tab.name}>{tab.name}</option>
                  ))}
                </select>
              </div>
              <div className="hidden lg:block">
                <div className="border-b border-gray-200">
                  <nav className="-mb-px flex space-x-8">
                    {tabs.map((tab) => (
                      <button
                        key={tab.name}
                        onClick={() => {
                          changeTab(tab.name);
                        }}
                        className={classNames(
                          tab.name === currentTab
                            ? "border-primaryColor text-primaryColor"
                            : "border-transparent text-gray-500 hover:border-primaryColor-dark hover:text-primaryColor-dark",
                          "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                        )}
                      >
                        {tab.name}
                      </button>
                    ))}
                  </nav>
                </div>
              </div>
              <SettingsMode />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
});

export default Settings;
