import React, { createContext, ReactNode, ReactElement } from 'react';
import { RootStore } from '@store/RootStore';

export const StoreContext = createContext<RootStore>({} as RootStore);

export type IStoreProps = {
    children: ReactNode;
};

export const StoreProvider = ({
  children
} : IStoreProps ): ReactElement => {
  return (
    <StoreContext.Provider value={new RootStore()}>{children}</StoreContext.Provider>
  )
}