/** Libraries */
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { ExclamationCircleFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import { toast } from "react-toastify";

/** Types */
import { IContact } from "@internal-types/ContactsTypes";

/** Store */
import { useStores } from "@hooks/useStores";

/** Services */
import { listContacts, updateContact } from "@services/rest/contact";
import { addImageURL, getNextContactDate } from "@services/helper/util";
import { IAddContact } from "@internal-types/AddContactsTypes";

const ContactList = () => {
  const { contactsStore } = useStores();
  const history = useHistory();

  const handleSelectContact = (contact: IContact) => {
    contactsStore.setSelectedContact(contact);
    //contactsStore.toggleContactDetailsDrawer(true);
    history.push(`/contacts/${contact.id}`);
  };

  const handleUpdateNextContact = async (values: any) => {
    const next_contact = getNextContactDate(values.metadata.frequency.id);

    const contact = {
      ...values,
      id: values.id,
      metadata: {
        ...values.metadata,
        next_contact: next_contact,
      },
    } as IAddContact;

    try {
      const response = await updateContact({ contact });

      if (response.data.success) {
        toast.success(
          `Successfully updated the next schedule to reach out ${values.name}.`
        );
        contactsStore.setSelectedContact(values);
        await getContactList();
      } else {
        toast.error(
          `Failed to update the next schedule for reaching out ${values.name}.`
        );
      }
    } catch (error) {
      toast.error("Error occured. Please try again later.");
    }
  };

  const getContactList = async () => {
    try {
      const {
        data: { args },
      } = await listContacts();

      if (args) {
        args.sort((a: IContact, b: IContact) => {
          if (a.name && b.name) {
            return a.name.localeCompare(b.name);
          }

          return 0;
        });

        addImageURL(args);
        contactsStore.setContactList(args);
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  return (
    <nav className="flex-1 min-h-0 overflow-y-auto" aria-label="Directory">
      <ul className="relative z-0 divide-y divide-gray-200">
        {contactsStore.filteredContactList.length <= 0 &&
          contactsStore.filterValue && (
            <div className="relative px-6 py-3 flex items-center justify-center">
              <p className="text-center">{`No ${contactsStore.filterValue} found in your contacts.`}</p>
            </div>
          )}
        {contactsStore.filteredContactList.map((person: IContact) => (
          <li key={person.id} onClick={() => handleSelectContact(person)}>
            <div className="relative px-6 py-3 flex items-center space-x-3 hover:bg-gray-50">
              <div className="flex-shrink-0 z-10">
                {dayjs(person.metadata?.next_contact).isBefore(dayjs()) && (
                  <button
                    className="text-red-400"
                    onClick={() => handleUpdateNextContact(person)}
                  >
                    <ExclamationCircleFilled />
                  </button>
                )}
              </div>
              <div className="flex-shrink-0">
                <img
                  className="h-10 w-10 rounded-full"
                  src={person.imageUrl}
                  alt=""
                />
              </div>
              <div className="flex-1 min-w-0">
                <a className="focus:outline-none">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="contact contact-name">{person.name}</p>
                  <p className="contact contact-title">{person.title || "-"}</p>
                </a>
              </div>
              <div>
                <ChevronRightIcon
                  className="-mr-1 ml-2 h-5 w-5"
                  aria-hidden="true"
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default observer(ContactList);
