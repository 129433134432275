/** Libraries */
import _ from 'lodash';
import { observer } from 'mobx-react-lite';

/** Organisms */
import OnboardingStatus from '@organisms/OnboardingStatus';
import BasicInformation from '@organisms/BasicInformation';
import BookmarkletInstallation from '@organisms/BookmarkletInstallation';
import AddFirstContact from '@organisms/AddFirstContact';

/** Store */
import { useStores } from '@hooks/useStores';
import { useEffect } from 'react';

/** Constants */
import { CURRENT_STEPS } from "@constants/onboardingSteps";

const Onboarding = () => {
  const { onboardingStore, userStore } = useStores();

  useEffect(() => {
    const { step } = userStore.metadata;

    if (step === CURRENT_STEPS.BOOKMARKLET_INSTALL) {
      onboardingStore.setOnBoardingStatus(CURRENT_STEPS.BASIC_INFO, "complete");
      onboardingStore.setOnBoardingStatus(CURRENT_STEPS.BOOKMARKLET_INSTALL, "current");
    } else if (step === CURRENT_STEPS.ADD_FIRST_CONTACT) {
      onboardingStore.setOnBoardingStatus(CURRENT_STEPS.BASIC_INFO, "complete");
      onboardingStore.setOnBoardingStatus(CURRENT_STEPS.BOOKMARKLET_INSTALL, "complete");
      onboardingStore.setOnBoardingStatus(CURRENT_STEPS.ADD_FIRST_CONTACT, "current");
    }
  }, []);

  const OnBoardingCurrentStep = observer(() => {
    const currentStep = _.find(onboardingStore.onboardingSteps, (step) => step.status === 'current');
    if (currentStep?.id === CURRENT_STEPS.BOOKMARKLET_INSTALL) {
      return <BookmarkletInstallation />;
    } else if (currentStep?.id === CURRENT_STEPS.ADD_FIRST_CONTACT) {
      return <AddFirstContact />;
    }

    return <BasicInformation />;
  });

  return (
    <div className="bg-onboarding bg-cover h-screen overflow-y-auto">
      <div className="flex bg-primaryColor justify-center p-5">
        <span className="text-lg font-bold align-middle text-white content-center sm:text-2xl sm:truncate">
          My Vital Few&#8482;: Set up your account
        </span>
      </div>
      <main className="flex flex-col">
        <OnboardingStatus/>
        <OnBoardingCurrentStep />
      </main>
    </div>
  );
};

export default observer(Onboarding);
