/**
 * Libraries
 */
import React from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";

/**
 * Styling
 */
import "./index.less";

/**
 * Components
 */
import Router from "@/components/routes/Router";

/**
 * Provider
 */
import { StoreProvider } from "@store/index";

/**
 * Utils
 */
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE!}
      scope="read:current_user update:current_user_metadata"
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <StoreProvider>
        <Router />
      </StoreProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
