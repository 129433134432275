/** Libraries */
import { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Form, Input, Button, Checkbox } from "antd";
import { observer } from "mobx-react-lite";
import _ from "lodash";

/** Store */
import { useStores } from "@hooks/useStores";

/** Services */
import { updateCategories, listCategories } from "@services/rest/user";

const EditCategoryModal = () => {
  const [form] = Form.useForm();
  const { categoryStore, contactsStore } = useStores();

  useEffect(() => {
    function setInitialCategoryFields() {
      categoryStore.categories.forEach((value) => {
        const fieldName = value.toLocaleLowerCase().replace(/ +/g, "");

        form.setFieldsValue({ [fieldName]: value });
      });
    }

    setInitialCategoryFields();
  }, [categoryStore.categories]);

  const handleOnFinish = async (values: {}) => {
    const categoryArray = _.values(values) as [];
    const data = { categories: categoryArray };
    const isFieldTouched = form.isFieldsTouched();    

    try {
      if (!isFieldTouched) return;
      
      const response = await updateCategories(data);

      if (response.data.success) {
        await getListCategory();
      }
    } catch (e) {}
    finally {
      contactsStore.categories.forEach((value: boolean, index: number) => {
        contactsStore.setSelectedCategories(categoryStore.categories[index], index, value);
      });
      categoryStore.toggleCategoryModal(false);
    }
  };

  const getListCategory = async () => {
    try {
      const {data: { args, success, error }} = await listCategories();

      if (success && args) {
        categoryStore.setCategories(args);
      } else {
        console.log("Something went wrong", error);
      }
    } catch (error) {
      console.log("Failed getting category list: ", error);
    }
  };

  const handleCancel = () => {
    categoryStore.toggleCategoryModal(false);
  };

  const onChangeCheckBox = (checked: boolean, index: number) => {
    contactsStore.setCategoryValue(index, checked);
  };

  return (
    <Transition.Root show={categoryStore.showCategoryModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={() => handleCancel()}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={handleCancel}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="flex justify-center">
                <p>Select 1 or more categories, or click to edit/change</p>
              </div>
              <Form
                form={form}
                name="category"
                onFinish={handleOnFinish}
                autoComplete="off"
              >
                <div className="flex pt-1">
                  <div className="flex-shrink-0 flex-flex-col">
                    {contactsStore.categories.map((value: boolean, index: number) => (
                      <div className="flex-1 pr-1">
                        <Form.Item>
                          <Checkbox
                            defaultChecked={value}
                            onChange={(e) => onChangeCheckBox(e.target.checked, index)}
                          />
                        </Form.Item>
                      </div>
                    ))}
                  </div>

                  <div className="flex-1 flex flex-col">
                    {categoryStore.categories.map((value, index) => (
                      <Form.Item
                        name={value.toLocaleLowerCase().replace(/ +/g, "")}
                        rules={[
                          {
                            required: true,
                            message: "Required field.",
                          },
                        ]}
                      >
                        { index === 0 ? <Input autoFocus/> : <Input />}
                      </Form.Item>
                    ))}
                  </div>
                </div>
                <div className="flex space-x-2 justify-center">
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    style={{ paddingRight: "32px", paddingLeft: "32px", borderRadius: "20px" }}
                  >
                    OK
                  </Button>
                </div>
              </Form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default observer(EditCategoryModal);
