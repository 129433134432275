import { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { observer } from "mobx-react-lite";

/** Layouts */
import Layout from "@templates/Layout";

/** Pages */
import Contacts from "@pages/Contacts";
import Settings from "@pages/Settings";
import Upgrade from "@pages/Upgrade";
import Onboarding from "@pages/Onboarding";
import SuccessPayment from "@pages/SuccessPayment";
import FailedPayment from "@pages/FailedPayment";
import Splash from "@pages/Splash";
import HelpFAQ from "@pages/HelpFAQ";
import TermsAndCondition from "@pages/TermsAndCondtions";
import PrivacyPolicy from "@pages/PrivacyPolicy";
import NonExistingUser from "@pages/NonExistingUser";
import ReRoute from "@pages/ReRoute";
import CheckUser from "@pages/CheckUser";

/** Atoms */
import Loading from "@atoms/Loading";
import LeavePagePrompt from "@atoms/LeavePagePrompt";

/** Services */
import { loadCurentUser } from "@services/rest/user";

/** Store */
import { useStores } from "@hooks/useStores";

const Routes = observer(() => {
  const { isLoading, error, isAuthenticated, getAccessTokenSilently, user } =
    useAuth0();
  const [appLoading, setAppLoading] = useState(true);
  const { userStore } = useStores();

  // By default user is existing this is only used if signed in using a non existing user using 3rd party sign up
  const [userExisting, setUserExisting] = useState(true);

  useEffect(() => {
    // This is to get the token
    if (isAuthenticated && !isLoading && user) {
      getAccessTokenSilently({
        // Create ENV
        audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
        scope: "read:current_user",
      }).then((token) => {
        // Set token for authenticated request
        localStorage.setItem("token", token);

        // Check if user is coming from login
        if (window.location.pathname === "/check-user") {
          user.fromLogin = true;
        }

        // This is to automatically register user or fetch the user
        loadCurentUser(user).then(({ data: { args, errors } }) => {
          if (errors) {
            setUserExisting(false);
            setAppLoading(false);
            return;
          }

          if (args) {
            userStore.setUserDetail(args);
          }

          if (userStore.metadata?.step === 4) {
            userStore.toggleOnboardingStatus();
          }

          // This is the final loading state
          setAppLoading(false);
        });
      });
    }
  }, [isAuthenticated, getAccessTokenSilently, isLoading, user]);

  if (isLoading) {
    return <Loading />;
  }

  // This is only used when loggin in using 3rd party libs and user is not existing
  if (!userExisting) {
    return <NonExistingUser />;
  }

  if (!isAuthenticated) {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <Splash />
          </Route>
          <Route exact path="/termsandconditions">
            <TermsAndCondition />
          </Route>
          <Route exact path="/privacypolicy">
            <PrivacyPolicy />
          </Route>
          <Route exact path="/reroute">
            <ReRoute />
          </Route>
          <Route path="/">
            <Splash />
          </Route>
        </Switch>
      </Router>
    );
  }

  if (appLoading) {
    return <Loading />;
  }

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (!userStore.metadata?.onboardingStatus) {
    return <Onboarding />;
  }

  return (
    <Router
      getUserConfirmation={(message, callback) => {
        return LeavePagePrompt(message, callback);
      }}
    >
      <Switch>
        <Route exact path="/">
          <Layout>
            <Contacts />
          </Layout>
        </Route>
        <Route exact path="/contacts/add">
          <Layout>
            <Contacts mode="add" />
          </Layout>
        </Route>
        <Route exact path="/contacts/edit">
          <Layout>
            <Contacts mode="edit" />
          </Layout>
        </Route>
        <Route exact path="/contacts/:id">
          <Layout>
            <Contacts />
          </Layout>
        </Route>
        <Route exact path="/settings">
          <Layout>
            <Settings />
          </Layout>
        </Route>
        <Route exact path="/help">
          <Layout>
            <HelpFAQ />
          </Layout>
        </Route>
        <Route exact path="/upgrade">
          <Layout>
            <Upgrade />
          </Layout>
        </Route>
        <Route exact path="/success">
          <SuccessPayment />
        </Route>
        <Route exact path="/failed">
          <FailedPayment />
        </Route>
        <Route exact path="/termsandconditions">
          <TermsAndCondition />
        </Route>
        <Route exact path="/privacypolicy">
          <PrivacyPolicy />
        </Route>
        <Route exact path="/check-user">
          <CheckUser />
        </Route>
        <Route path="/">404</Route>
      </Switch>
    </Router>
  );
});

export default Routes;
