/** Types */
import { IOnboardingStepsProps } from '@internal-types/PropTypes';

const UpcomingStep = ({step}: IOnboardingStepsProps) => {
  return (
    <div className="group">
      <span
        className="absolute top-0 left-0 w-1 h-full bg-transparent group-hover:bg-gray-200 lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
        aria-hidden="true"
      />
      <span className="px-6 py-5 flex items-start text-sm font-medium">
        <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
          <span className="text-base font-semibold text-gray-700 tracking-wide">
            {step.name}
          </span>
          <span className="text-xs font-medium text-gray-500">
            {step.description}
          </span>
        </span>
      </span>
    </div>
  );
};

export default UpcomingStep;
