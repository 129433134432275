/** Config */
import { DOMAIN } from '@/config';

export const CURRENT_STEPS = {
  BASIC_INFO: 1,
  BOOKMARKLET_INSTALL: 2,
  ADD_FIRST_CONTACT: 3,
}

export const addFirstContactSteps = [
  {
    id: 1,
    content: "Search LinkedIn, Facebook etc. and find a contact you'd like to add to your My Vital Few&#8482;.",
  },
  {
    id: 2,
    content: "Highlight your connections' full name and click the bookmarklet you just added.",
  },
  {
    id: 3,
    content: "The bookmarklet will redirect you to the add contact page.  Finish filling out the rest of the connection's information and click Add",
  },
];

export const INSTALLATION_STEPS = [
  {
    id: 1,
    content: "If you don't see the bookmark bar press CTRL-SHIFT-B first",
  },
  {
    id: 2,
    content: "Drag and drop this link to your bookmark bar: ",
    href: `javascript:(()=>{window.open(\`${DOMAIN}`+"/contacts/add?name=${window.getSelection().toString()}&location=${window.location.href}`)})();",

    action: "MVF Add Contact",
  },
];