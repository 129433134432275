/** Libraries */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom"

/** Atoms */
import Loading from "@atoms/Loading";
const CheckUser = () => {
  const { push } = useHistory();

  useEffect(()=>{
	push('/');
  },[]);

  return (
    <Loading />
  );
};

export default CheckUser;
