/** Libraries */
import { CameraIcon } from "@heroicons/react/solid";
import { observer } from "mobx-react-lite";

/** Store */
import { useStores } from '@hooks/useStores';

/** Assets */
import ContactImagePlaceholder from '@assets/contact-image-placeholder.png';

const UploadImage = () => {
  const { contactsStore } = useStores();
  return (
    <div className="-mt-12 xl:-mt-16 xl:space-x-5">
      <div className="flex justify-center">
        <div className="relative">
          <img
            className={`h-24 w-24 rounded-full ring-8 ring-white md:h-32 md:w-32 bg-gray-50 p-${contactsStore.mode === "add" ? '3' : '0'}`}
            src={contactsStore.mode === "edit" ? contactsStore.selectedContact?.imageUrl : ContactImagePlaceholder}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default observer(UploadImage);
