/** Libraries */
import { useState } from 'react';
import { useHistory } from "react-router";
import { SpeakerphoneIcon, XIcon } from '@heroicons/react/outline';
import { useStores } from '@hooks/useStores';
import dayjs from 'dayjs';

const UpgradeBanner = () => {
  const { userStore } = useStores();
  const history = useHistory();
  const [isSubscriptionExpired] = useState(
    userStore.metadata.subscription?.current_period_end === dayjs().unix()
  );

  const toggleBanner = () => {
    userStore.toggleUpgradeBanner(false);
  }

  const goToUpgradePage = () => {
    history.push("/upgrade");
  }

  return (
    <div className="bg-primaryColor">
      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center">
            <span className="flex p-2 rounded-lg bg-primaryColor-dark">
              <SpeakerphoneIcon
                className="h-6 w-6 text-white"
                aria-hidden="true"
              />
            </span>
            <div className="ml-3 font-medium text-white truncate">
              {isSubscriptionExpired ? (
                <>
                  <span className="md:hidden text-lg">Renew your plan now!</span>
                  <span className="hidden md:inline text-lg">
                  Your plan has expired. RENEW your subscription to have more contacts!
                  </span>
                </>
              ) : (
                <>
                  <span className="md:hidden text-lg">Upgrade now!</span>
                  <span className="hidden md:inline text-lg">
                    You are in Trial mode. To add more contacts UPGRADE NOW!
                  </span>
                </>
              )}
            </div>
          </div>
          <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
            <button
              onClick={goToUpgradePage}
              className="w-full sm:flex items-center justify-center button-upgrade-banner text-primaryColor hover:text-primaryColor-dark"
            >
              Upgrade
            </button>
          </div>
          <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
            <button
              type="button"
              onClick={toggleBanner}
              className="-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeBanner;
