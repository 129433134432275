/** Libraries */
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router';
import { ExclamationIcon } from '@heroicons/react/outline';

const FailedPayment = () => {
  const history = useHistory();

  const handleGoToContactsPage = () => {
    history.push('/');
  };

  return (
<div className="relative h-screen flex overflow-hidden bg-white">
      <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
        <div className="flex-1 relative z-0 flex overflow-hidden">
          <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
            <div>
              <img
                className="h-20 w-full object-cover lg:h-24"
                src="https://www.fitzdrakesearch.com/i/bg_industry.png"
                alt=""
              />
            </div>
            <div className="flex justify-center">
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-24 w-24 rounded-full bg-red-100">
                    <ExclamationIcon
                      className="h-20 w-20 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5 divide-y divide-gray-200">
                    <div>
                      <h3 className="text-3xl leading-6 font-medium text-gray-900">
                        Payment failed
                      </h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Please try again later.
                        </p>
                      </div>
                    </div>
                    <div className="mt-3 text-center">
                      <div className="mt-2">
                        <p className="text-sm text-gray-700">We apologize for the inconvenience.</p>
                      </div>
                      <div className="mt-2">
                        <p className="text-xs text-gray-700">You may reach out to our customer service. customer.service@email.com</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    onClick={handleGoToContactsPage}
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-primaryColor text-base font-medium text-white hover:bg-primaryColor-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primaryColor-light sm:text-sm"
                  >
                    Go back to contacts page
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default observer(FailedPayment);
