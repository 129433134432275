/** Libraries */
import { PencilIcon, TrashIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

/** Store */
import { useStores } from "@hooks/useStores";

/** Types */
import DeleteContact from "@molecules/DeleteContact";

const ContactHeader = () => {
  const { contactsStore } = useStores();
  const history = useHistory();

  const toggleEditContactForm = () => {
    history.push("/contacts/edit");
  };

  const toggleDeleteDialog = () => {
    contactsStore.toggleDeleteContactDialog(true);
  };

  const handleOnClickCall = () => {
    if (!contactsStore.selectedContact?.phone) {
      toast.warning("No mobile number found.", { autoClose: 5000 });

      return;
    }

    window.location.href = `tel:${contactsStore.selectedContact?.phone.trim()}`;
  };

  const handleOnClickMessage = () => {
    if (!contactsStore.selectedContact.email) {
      toast.warning("No email address found.", { autoClose: 5000 });

      return;
    }

    window.location.href = `mailto:${contactsStore.selectedContact.email}`;
  };

  return (
    <>
      <DeleteContact />
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="-mt-12 xl:-mt-16">
          <div className="absolute top-0 right-0">
            <div className="flex justify-center lg:justify-end">
              <button
                type="button"
                onClick={toggleEditContactForm}
                className="m-2 inline-flex justify-center button-edit-delete"
              >
                <PencilIcon
                  className="-ml-1 mr-2 h-5 w-5 text-white"
                  aria-hidden="true"
                />
                <span>Edit</span>
              </button>
              <button
                type="button"
                onClick={toggleDeleteDialog}
                className="m-2 inline-flex justify-center button-edit-delete"
              >
                <TrashIcon
                  className="-ml-1 mr-2 h-5 w-5 text-white"
                  aria-hidden="true"
                />
                <span>Delete</span>
              </button>
            </div>
          </div>
          <div className="flex justify-center">
            <img
              className="h-24 w-24 rounded-full ring-8 ring-white md:h-32 md:w-32"
              src={contactsStore.selectedContact?.imageUrl}
              alt=""
            />
          </div>
          <div className="mt-6 flex flex-col ml-0">
            <h1 className="text-2xl font-bold text-primaryColor truncate text-center">
              {contactsStore.selectedContact?.name}
            </h1>
            <p className="text-center text-md font-semibold flex items-center justify-center">
              
              {contactsStore.selectedContact?.title &&
              <p className={`mb-0 w-full ${contactsStore.selectedContact?.metadata?.contact_added ? 'text-right pr-2' : ''}`}>
                {`${contactsStore.selectedContact?.title}`}
              </p>
              }

              {contactsStore.selectedContact?.metadata?.contact_added &&
              <p className={`mb-0 w-full pl-2 ${contactsStore.selectedContact?.title ? 'text-left' : 'text-center'}`}>
                {`Added: ${contactsStore.selectedContact?.metadata?.contact_added}`}
              </p>
              }
            </p>
          </div>

          <div>
            <div className="flex flex-col md:flex-row md:justify-center ml-5">
              <button
                type="button"
                className="m-2 inline-flex justify-center md:order-last button-make-call"
                onClick={handleOnClickCall}
              >
                Make a Call
              </button>
              <button
                type="button"
                className="m-2 md:order-first button button-cancel"
                onClick={handleOnClickMessage}
              >
                Message
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactHeader;
