/** Library */
import { useEffect, useState } from "react";
import {
  ExternalLinkIcon,
  MailOpenIcon,
  PhoneIcon,
} from "@heroicons/react/solid";
import { observer } from "mobx-react-lite";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

/** Molecules */
import ContactDetailElement from "@molecules/ContactDetailElement";

/** Organisms */
import ContactHeader from "@molecules/ContactHeader";

/** Store */
import { useStores } from "@hooks/useStores";
import Tag from "antd/lib/tag";

const ContactDetails = () => {
  const { contactsStore, categoryStore } = useStores();
  const [stringCategories, setStringCategories] = useState([] as Array<string>);

  useEffect(() => {
    function transformSelectedCategories() {
      if (contactsStore.contactList.length > 0) {
        const selectedCategories: Array<string> = [];

        contactsStore.selectedContact?.metadata?.category?.forEach(
          (category, index) => {
            if (category) {
              selectedCategories.push(categoryStore.categories[index]);
            }
          }
        );

        setStringCategories(selectedCategories);
      }
    }

    transformSelectedCategories();
  }, [contactsStore.selectedContact]);

  const goToLinkedInProfile = () => {
    window.open(contactsStore.selectedContact?.profileUrl);
  };

  return (
    <>
      <ContactHeader />
      <div className="bg-white m-3 px-4 py-5 sm:px-6">
        <div className="flex flex-col space-y-3 md:space-y-0 md:flex-row md:space-x-3">
          <div className="flex-1">
            <dt className="text-sm font-medium text-gray-500">CATEGORY</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {stringCategories.map((category) => (
                <Tag>{category}</Tag>
              ))}
            </dd>
          </div>
          <ContactDetailElement
            detailLabel="OUTREACH FREQUENCY"
            detailValue={
              contactsStore.selectedContact?.metadata?.frequency?.id ===
              "showall"
                ? "-"
                : contactsStore.selectedContact?.metadata?.frequency?.name
            }
          />

          <div className="flex-1">
            <dt className="text-sm font-medium text-gray-500">PROFILE URL</dt>
            <dd
              onClick={goToLinkedInProfile}
              className="mt-1 text-sm text-gray-900"
            >
              {contactsStore.selectedContact?.profileUrl ? (
                <button className="mt-1 text-sm text-gray-900 hover:text-gray-900 group flex items-center">
                  {contactsStore.selectedContact?.name}
                  <ExternalLinkIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </button>
              ) : (
                "-"
              )}
            </dd>
          </div>
          <div className="flex-1">
            <dt className="text-sm font-medium text-gray-500">EMAIL</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {contactsStore.selectedContact?.email ? (
                <address>
                  <a
                    href={`mailto:${contactsStore.selectedContact.email}`}
                    className="mt-1 text-sm text-gray-900 hover:text-gray-900 group flex items-center"
                  >
                    <MailOpenIcon
                      className="h-5 w-5 mr-1 text-gray-400"
                      aria-hidden="true"
                    />
                    {contactsStore.selectedContact?.email}
                  </a>
                </address>
              ) : (
                "-"
              )}
            </dd>
          </div>
        </div>
      </div>
      <div className="bg-white m-3 px-4 py-5 sm:px-6">
        <div className="flex flex-col space-y-3 md:space-y-0 md:flex-row md:space-x-3">
          <div className="flex-1">
            <dt className="text-sm font-medium text-gray-500">PHONE</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {contactsStore.selectedContact?.phone ? (
                <a
                  href={`tel:${contactsStore.selectedContact.phone.trim()}`}
                  className="mt-1 text-sm text-gray-900 hover:text-gray-900 group flex items-center"
                >
                  <PhoneIcon
                    className="h-5 w-5 mr-1 text-gray-400"
                    aria-hidden="true"
                  />
                  {contactsStore.selectedContact?.phone}
                </a>
              ) : (
                "-"
              )}
            </dd>
          </div>
          <ContactDetailElement
            detailLabel="TITLE"
            detailValue={contactsStore.selectedContact?.title || "-"}
          />
          <ContactDetailElement
            detailLabel="COMPANY"
            detailValue={contactsStore.selectedContact?.company || "-"}
          />
        </div>
      </div>
      <div className="bg-white m-3 px-4 py-5 sm:px-6">
        <div className="flex space-x-3">
          <div className="flex-1">
            <dt className="text-sm font-medium text-primaryColor-light">
              NOTES
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              <ReactQuill
                theme="bubble"
                readOnly={true}
                value={contactsStore.selectedContact?.metadata?.notes}
              />
            </dd>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(ContactDetails);
