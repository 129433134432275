/** Libraries */
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";

const LOGIN = "login";
const SIGNUP = "signUp";

const LoginSignUp = () => {
  const { loginWithRedirect } = useAuth0();
  const history = useHistory();

  const handleRedirectAuth0 = (mode: string) => {
    return () => {
      loginWithRedirect({
        action: mode,
        redirectUri:
          mode === LOGIN
            ? `${window.location.origin}/check-user`
            : window.location.origin,
      });
    };
  };

  return (
    // <div className="flex max-w-4xl mx-auto">
    //   <div className="flex-1 content-center">
    //     <div className="bg-white shadow-2xl rounded-2xl">
    //       <div className="flex flex-col md:flex-row justify-center items-center pt-6">
    //         <h1 className="text-4xl self-center">Get Started for Free!</h1>
    //       </div>
    //       <div>
    //         <p className="text-center">Do you have an account?</p>
    //       </div>
    //       <div className="px-12 pb-6 justify-center">
    //         <button
    //           className="py-2 w-full rounded-full bg-primaryColor-light text-white truncate"
    //           onClick={handleRedirectAuth0(LOGIN)}
    //         >
    //           Login
    //         </button>
    //       </div>
    //       <div>
    //         <p className="text-center">
    //           Don't have an account? Sign up for free
    //         </p>
    //       </div>
    //       <div className="px-12 pb-3 justify-center">
    //         <button
    //           className="py-2 w-full rounded-full bg-primaryColor text-white truncate"
    //           onClick={handleRedirectAuth0(SIGNUP)}
    //         >
    //           Sign up
    //         </button>
    //       </div>
    //       <div className="flex justify-center p-5">
    //         <p className="text-xs">
    //           By registering you confirm that you accept the{" "}
    //           <button
    //             className="text-xs text-primaryColor hover:text-primaryColor-dark"
    //             onClick={() => history.push("/termsandconditions")}
    //           >
    //             Terms and Conditions
    //           </button>
    //           {" "}and{" "}
    //           <button
    //             className="text-xs text-primaryColor hover:text-primaryColor-dark"
    //             onClick={() => history.push("/privacypolicy")}
    //           >
    //             Privacy Policy
    //           </button>
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div className="max-w-sm rounded-3xl mx-auto py-6 px-6 md:px-12 bg-authContainerBg shadow-lg">
      <div className="pt-2">
        <h2 className="mb-0 text-2xl text-center font-bold">
          Get started for free{" "}
        </h2>
      </div>
      <div className="mt-10">
        <button
          type="button"
          onClick={handleRedirectAuth0(LOGIN)}
          className="text-white w-full bg-landingPageAccent hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center"
        >
          Login
        </button>
        <div className="flex items-center justify-between my-3">
          <span className="w-full border-b"></span>
          <a className="text-md text-center px-2 text-black">or</a>
          <span className="w-full border-b"></span>
        </div>

        <div>
          <button
            onClick={handleRedirectAuth0(SIGNUP)}
            type="button"
            className="w-full border-2 text-landingPageAccent border-landingPageAccent hover:bg-blue-800 hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center"
          >
            Sign up{" "}
          </button>
          <p className="mt-2 text-center">
            Don’t have an account? Sign up for free
          </p>
        </div>
      </div>
      <div className="text-center text-sm md:text-base px-2">
        By registering, you confirm that you accept the
        <span
          onClick={() => history.push("/termsandconditions")}
          className="text-blue-600 cursor-pointer"
        >
          {" "}
          Terms and Conditions{" "}
        </span>
        and
        <span
          onClick={() => history.push("/privacypolicy")}
          className="text-blue-600 cursor-pointer"
        >
          {" "}
          Privacy Policy
        </span>
      </div>
    </div>
  );
};

export default LoginSignUp;
