/** Libraries */
import React, { useEffect, useMemo, useState, useCallback, useRef } from "react";
import { observer } from "mobx-react-lite";
import { useHistory, Prompt } from "react-router";
import { Form, Input, Button, Select } from "antd";
import { toast } from "react-toastify";
import _ from "lodash";
import Quill, { DeltaStatic, Sources, Delta as DeltaType, RangeStatic } from "quill";
import ReactQuill, { UnprivilegedEditor } from "react-quill";
import "react-quill/dist/quill.snow.css";

/** Molecules */
import EditCategoryModal from "@molecules/EditCategoryModal";

/** Atoms */
import ProfileImage from "@atoms/ProfileImage";

/** Store */
import { useStores } from "@hooks/useStores";

/** Types */
import { IAddContact } from "@internal-types/AddContactsTypes";
import { IContact } from "@internal-types/ContactsTypes";

/** Services */
import { listContacts, updateContact } from "@services/rest/contact";
import {
  addImageURL,
  transformFrequencyOptions,
  getNextContactDate,
} from "@services/helper/util";
import CustomToolbar, { formats } from "@molecules/CustomToolbar";
import DatePicker from "@atoms/DatePicker";
import dayjs from "dayjs";

const EditContact = () => {
  const { contactsStore, categoryStore } = useStores();
  const [form] = Form.useForm();
  const history = useHistory();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [proceedEditContact, setProceedEditContact] = useState(false);
  const [contactData, setContactData] = useState({} as IContact);
  const [originalNotes, setOriginalNotes] = useState<string>("");
  const [notesHtml, setNotesHtml] = useState<string>("");
  const [notesPlainText, setNotesPlainText] = useState<string>("");
  const [notesLoaded, setNotesLoaded] = useState<boolean>(false);
  const quillRef = useRef<ReactQuill>(null)

   // <any> type used here as <HTMLInputElement> type causes an error:
  // showPicker() does not exist on HTMLInputElement type
  const dateRef = useRef<any>(null)

 // Had to define here to give access to dateRef,
  // Toggles visibility:
  function handleShowDatePicker() {
    dateRef?.current?.showPicker(); 
  }

  // Modules object for setting up the Quill editor
  // Had to define here to give access handleDatePicker
  const modules = {
    toolbar: {
      container: "#toolbar",
      handlers: {
        insert_date: handleShowDatePicker
      },
    },
    clipboard: {
      matchVisual: false,
    },
  };

  // Use a memo to remember modules object,
  // using modules directly in ReactQuill causes
  // an addRange error. see: https://github.com/quilljs/quill/issues/1940
  const moduleMemo = useMemo(() => modules, []);

  // Necessary for Quill.updateContents():
  const Delta = Quill.import("delta") as typeof DeltaType;

  // Defined here to give access to quillObject:
  // Respnsible for inserting date into editor.
  function handleDatePick(e:any) {
    const date = `${dayjs(e.target.value, "YYYY-MM-DD").format("DD-MMM-YYYY")} `;
    const DATE_LENGTH = date.length;

    const currentRangeStatic = quillRef?.current?.getEditor()?.getSelection();
    const cursorPosition = currentRangeStatic?.index;

    // Insert the date using Quill API:
    quillRef?.current
      ?.getEditor()
      ?.updateContents(
        new Delta()
          .retain(cursorPosition!)
          .insert(date)
      );

    // Set the cursor after the inserted date:
    const cursorPositionAfterInsert = cursorPosition! + DATE_LENGTH;
    quillRef?.current?.getEditor()?.setSelection({index: cursorPositionAfterInsert, length: 0} as RangeStatic)

    // Reset date input value to allow same date insert:
    dateRef.current.value = null;
  }

  useEffect(() => {
    setContactData(contactsStore.selectedContact);
  }, []);

  useEffect(() => {
    function handleSelectedCategories() {
      if (!contactData.metadata) return;

      contactsStore.setCategories(contactData.metadata?.category!);

      contactData.metadata?.category?.forEach((checked, index) => {
        if (checked) {
          contactsStore.setSelectedCategories(
            categoryStore.categories[index],
            index,
            checked
          );
        }
      });
    }

    handleSelectedCategories();

    return () => {
      contactsStore.resetCategories();
    };
  }, [contactData]);

  useEffect(() => {
    function setFormInitialValue() {
      if (_.isEmpty(contactData)) return;
      if (contactData.metadata?.notes) {
        console.log(
          `setOriginalNotes(contactData.metadata?.notes); ${contactData.metadata?.notes}`
        );
        setOriginalNotes(contactData.metadata?.notes);
      }
      setNotesHtml(contactData.metadata?.notes || "");
      setNotesLoaded(true);

      form.setFieldsValue({
        name: contactData.name,
        profileUrl: contactData.profileUrl,
        email: contactData.email,
        phone: contactData.phone,
        title: contactData.title,
        company: contactData.company,
        category: contactsStore.categories,
        frequency: {
          value: contactData.metadata?.frequency?.id,
          label: contactData.metadata?.frequency?.name,
        },
      });
    }

    setFormInitialValue();
  }, [contactData]);

  useEffect(() => {
    async function watchCategoryOnChange() {
      const result = contactsStore.categories.every((value) => value === false);
      if (result) {
        form.setFieldsValue({ category: undefined });
      } else {
        form.setFieldsValue({ category: true });

        if (!isFormDirty) setIsFormDirty(true);
      }
    }

    watchCategoryOnChange();
  }, [categoryStore.showCategoryModal]);

  // Callback function when user proceeds in editing a contact.
  // Listens when proceedEditContact state changes.
  useEffect(() => {
    function editContactCallback() {
      if (proceedEditContact) handleProceedEdit(form.getFieldsValue());
    }

    editContactCallback();
  }, [proceedEditContact]);

  const onCancel = () => {
    history.push("/");
  };

  const handleOnFinish = () => {
    setIsFormDirty(false);
    setProceedEditContact(true);
  };

  const handleProceedEdit = async (values: any) => {
    const next_contact = getNextContactDate(values.frequency.value);

    const contact = {
      id: contactData.id,
      name: values.name,
      profileUrl: values.profileUrl || "",
      email: values.email || "",
      phone: values.phone || "",
      title: values.title || "",
      company: values.company || "",
      imageUrl: `https://ui-avatars.com/api/?name=${values.name}`,
      metadata: {
        category: contactsStore.categories,
        frequency: { id: values.frequency.value, name: values.frequency.label },
        notes: notesHtml,
        plaintextNotes: notesPlainText,
        next_contact: next_contact,
        contact_added: contactsStore.selectedContact.metadata?.contact_added,
      },
    } as IAddContact;

    try {
      const response = await updateContact({ contact });

      if (response.data.success) {
        // toast.success(`Successfully edited ${contact.name}'s information.`);

        toast.success(`Successfully edited ${contact.name}'s information.`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        history.push(`/contacts/${contactData.id}`);
        contactsStore.setSelectedContact(contact);
        await getContactList();
      } else {
        // toast.error("Failed to edit contact.");

        toast.error(`Failed to edit contact.`, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      // toast.error("Error occured. Please try again later.");

      toast.error(`Error occured. Please try again later.`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const getContactList = async () => {
    try {
      const {
        data: { args },
      } = await listContacts();

      if (args) {
        args.sort((a: IContact, b: IContact) => {
          if (a.name && b.name) {
            return a.name.localeCompare(b.name);
          }

          return 0;
        });

        addImageURL(args);
        contactsStore.setContactList(args);
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const handleOnChange = () => {
    if (isFormDirty) return;

    setIsFormDirty(true);
  };

  const handleQuillChange = (
    content: string,
    delta: DeltaStatic,
    source: Sources,
    editor: UnprivilegedEditor
  ) => {
    setNotesHtml(content);
    const plainText = editor.getText();
    setNotesPlainText(plainText);
    // don't dirty a form with notes until an edit is made
    if (plainText.replace(/\s/g, "") !== originalNotes.replace(/\s/g, "")) {
      setIsFormDirty(true);
    }
  };

  return (
    <>
      <Prompt
        when={isFormDirty}
        message={JSON.stringify({
          header: "Warning!",
          content:
            "You have unsaved changes. Are you sure you want to exit the page?",
        })}
      />
      <EditCategoryModal />
      <ProfileImage />
      <div className="p-2 lg:p-10 space-y-8 divide-y divide-gray-200">
        <div className="bg-white p-5 lg:p-10">
          <Form
            form={form}
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout={"vertical"}
            onFinish={handleOnFinish}
            onChange={handleOnChange}
          >
            <Form.Item>
              <div className="flex space-x-2 justify-center lg:justify-end">
                <div className="md:order-first">
                  <Button
                    htmlType="button"
                    onClick={onCancel}
                    size="large"
                    style={{
                      paddingRight: "28px",
                      paddingLeft: "28px",
                      borderRadius: "20px",
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="md:ml-2 md:order-last">
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    style={{
                      paddingRight: "32px",
                      paddingLeft: "32px",
                      borderRadius: "20px",
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Form.Item>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your name.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <div className="flex flex-col md:flex-row md:space-x-4">
              <div className="flex-1">
                <Form.Item label="Title" name="title">
                  <Input />
                </Form.Item>
              </div>
              <div className="flex-1">
                <Form.Item label="Company" name="company">
                  <Input />
                </Form.Item>
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:space-x-4">
              <div className="flex-1 overflow-hidden truncate">
                <Form.Item
                  label="Category"
                  name="category"
                  rules={[
                    {
                      required: true,
                      message: "Please select a category.",
                    },
                  ]}
                >
                  <button
                    type="button"
                    className="border-2 p-1 w-full text-justify"
                    onClick={() => categoryStore.toggleCategoryModal(true)}
                  >
                    {_.compact(contactsStore.selectedCategories).join(", ") ||
                      `Show All`}
                  </button>
                </Form.Item>
              </div>
              <div className="flex-1">
                <Form.Item
                  label="Outreach Frequency"
                  name="frequency"
                  rules={[
                    {
                      required: true,
                      message: "Please select an outreach frequency",
                    },
                  ]}
                >
                  <Select
                    labelInValue
                    placeholder="Show All"
                    listHeight={32 * 9}
                    options={transformFrequencyOptions()}
                  ></Select>
                </Form.Item>
              </div>
            </div>
            <Form.Item label="Profile URL" name="profileUrl">
              <Input />
            </Form.Item>
            <div className="flex flex-col md:flex-row md:space-x-4">
              <div className="flex-1">
                <Form.Item
                  label="Email address"
                  name="email"
                  rules={[{ type: "email" }]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="flex-1">
                <Form.Item label="Phone Number" name="phone">
                  <Input />
                </Form.Item>
              </div>
            </div>
            {notesLoaded && (
              <div
                className="relative"
              >
                <DatePicker ref={dateRef} handleDatePick={handleDatePick}/>
                <CustomToolbar/>
                <ReactQuill
                  theme="snow"
                  defaultValue={originalNotes}
                  onChange={handleQuillChange}
                  ref={quillRef}
                  modules={moduleMemo}
                  formats={formats}
                />
              </div>
            )}
          </Form>
        </div>
      </div>
    </>
  );
};

export default observer(EditContact);
