import Input, { InputProps } from "antd/lib/input";
import React, {forwardRef} from "react";

export type Ref = React.MutableRefObject<any>;
export interface IProps {   
    children?: React.ReactNode;
    handleDatePick: (e:React.SyntheticEvent) => void;
}

const DatePicker = forwardRef<HTMLInputElement | null, IProps>(
    function DatePicker(props, ref) {
        
        const {handleDatePick} = props

        return (
            <div
            className="absolute top-0"
            >
            <span className="datepicker-toggle">
                <span className="datepicker-toggle-button"></span>
                <input
                    ref={ref}
                    className="datepicker-input"
                    data-date-inline-picker="true"
                    onChange={handleDatePick}
                    type="date"
                />
            </span>
        </div>
        )
    }
)

export default DatePicker;