/** Libraries */
import { observer } from "mobx-react-lite";
import { Form, Input, Button } from "antd";

/** Store */
import { useStores } from "@hooks/useStores";

/** Services */
import { saveStep } from "@services/rest/user";

/** Constants */
import { CURRENT_STEPS } from "@constants/onboardingSteps";
import { useEffect } from "react";

export type BasicInformationType = {
  firstName: string;
  lastName: string;
  email: string;
  address?: string;
  phone?: string;
};

const BasicInformation = () => {
  const { onboardingStore, userStore } = useStores();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      firstName: userStore.firstName,
      lastName: userStore.lastName,
      email: userStore.contactInfo.email,
    });
  }, []);

  const handleOnFinish = async (data: BasicInformationType) => {
    const step = {
      step: 2,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      address: data.address || "",
      phone: data.phone || "",
    };

    try {
      const response = await saveStep({ step });

      if (response.data.success) {
        onboardingStore.setOnBoardingStatus(
          CURRENT_STEPS.BASIC_INFO,
          "complete"
        );
        onboardingStore.setOnBoardingStatus(
          CURRENT_STEPS.BOOKMARKLET_INSTALL,
          "current"
        );
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  return (
    <main>
      <div className="max-w-4xl mx-auto px-5">
        <div className="flex px-4 py-10 sm:px-0">
          <div className="bg-white shadow-2xl overflow-hidden flex-1 px-12">
            <div className="pt-8 sm:pt-10">
              <h3 className="onboarding-title">Personal Information</h3>
            </div>
            <Form
              form={form}
              name="basicInfo"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              layout={"vertical"}
              onFinish={handleOnFinish}
              autoComplete="off"
            >
              <div className="flex flex-col md:flex-row md:space-x-4">
                <div className="flex-1">
                  <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your first name.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className="flex-1">
                  <Form.Item
                    label="Last Name"
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your last name.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:space-x-4">
                <div className="flex-1">
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email.",
                        type: "email",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className="flex-1">
                  <Form.Item label="Phone" name="phone">
                    <Input />
                  </Form.Item>
                </div>
              </div>
              <Form.Item>
                <div className="flex space-x-2 justify-center lg:justify-end">
                  <div className="md:ml-2 md:order-last">
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      style={{ paddingRight: "32px", paddingLeft: "32px", borderRadius: "20px" }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default observer(BasicInformation);
