/** Libraries */
import { observer } from "mobx-react-lite";

/** Molecules */
import CompletedStep from "@molecules/CompletedStep";
import CurrentStep from "@molecules/CurrentStep";
import UpcomingStep from "@molecules/UpcomingStep";

/** Store */
import { useStores } from "@hooks/useStores";

const OnboardingStatus = () => {
  const { onboardingStore } = useStores();

  return (
    <div className="bg-stepsNavColor">
      <nav
        className="mx-auto max-w-7xl px-4 sm:px-38 lg:px-40"
        aria-label="Progress"
      >
        <ol className="rounded-md overflow-hidden lg:flex lg:rounded-none">
          {onboardingStore.onboardingSteps.map((step) => (
            <li key={step.name} className="relative overflow-hidden lg:flex-1">
              {step.status === "complete" ? (
                <CompletedStep step={step} />
              ) : step.status === "current" ? (
                <CurrentStep step={step} />
              ) : (
                <UpcomingStep step={step} />
              )}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};

export default observer(OnboardingStatus);
