/** Libraries */
import { useEffect } from "react";
import {
  PlusSmIcon as PlusSmIconOutline,
  XIcon,
} from "@heroicons/react/outline";
import { observer } from "mobx-react-lite";
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router";
import { toast } from "react-toastify";
import _ from "lodash";
import dayjs from "dayjs";

/** Services */
import { listContacts } from "@services/rest/contact";
import { listCategories } from "@services/rest/user";
import { addImageURL } from "@services/helper/util";

/** Store */
import { useStores } from "@hooks/useStores";

/** Types */
import { IContact } from "@internal-types/ContactsTypes";
import { IContactProps } from "@internal-types/PropTypes";

/** Organisms */
import ContactList from "@organisms/ContactList";
import ContactDetails from "@organisms/ContactDetails";
import AddContact from "@organisms/AddContact";
import EditContact from "@organisms/EditContact";

/** Molecules */
import ContactSearchBar from "@molecules/ContactSearchBar";

/** Assets */
import ContactHeaderImage from "@assets/contacts-header.png";

const Contact = ({ mode }: IContactProps) => {
  const { userStore, contactsStore, categoryStore } = useStores();
  const history = useHistory();
  const urlParams = useParams() as { id: string | "" };
  const match = useRouteMatch(`/contacts/${urlParams.id}`);
  const location = useLocation();

  useEffect(() => {
    async function getContactList() {
      try {
        const {
          data: { args },
        } = await listContacts();

        if (args && args.length > 0) {
          args.sort((a: IContact, b: IContact) => {
            if (a.name && b.name) {
              return a.name.localeCompare(b.name);
            }

            return 0;
          });

          addImageURL(args);
          contactsStore.setContactList(args);

          if (match && match.isExact) {
            const contactIndex = _.findIndex(args, { id: urlParams.id });
            contactsStore.setSelectedContact(args[contactIndex]);
          } else {
            contactsStore.setSelectedContact(args[0]);
          }
        }
      } catch (error) {}
    }

    getContactList();
  }, []);

  useEffect(() => {
    async function getListCategory() {
      try {
        const {
          data: { args, success, error },
        } = await listCategories();

        if (success && args) {
          categoryStore.setCategories(args);
        } else {
          console.log("Something went wrong", error);
        }
      } catch (error) {
        console.log("Failed getting category list: ", error);
      }
    }

    getListCategory();
  }, []);

  useEffect(() => {
    function checkOutreachFrequencyMatch() {
      if (contactsStore.contactList.length <= 0) return;

      contactsStore.contactList.forEach((contact) => {
        if (dayjs().isSame(dayjs(contact?.metadata?.next_contact), "day")) {
          // toast.success(`Reach out to ${contact.name} today!`);

          toast.success(`Reach out to ${contact.name} today!`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
    }

    checkOutreachFrequencyMatch();
  }, [contactsStore.contactList]);

  const toggleAddContactForm = () => {
    const { subscription } = userStore.metadata;

    if (
      !subscription ||
      subscription.cancel_at_period_end ||
      subscription.current_period_end === dayjs().unix()
    ) {
      if (contactsStore.contactList.length >= contactsStore.contactLimit) {
        history.push("/upgrade");
        return;
      }
    }

    history.push("/contacts/add");
  };

  const ContactMode = observer(() => {
    contactsStore.setMode(mode!);

    if (mode === "add") {
      contactsStore.toggleContactDetailsDrawer(true);
      return <AddContact />;
    } else if (mode === "edit") {
      return <EditContact />;
    } else if (_.isEmpty(contactsStore.filteredContactList)) {
      return <></>;
    }

    return <ContactDetails />;
  });

  const handleCloseDrawer = () => {
    history.push("/");
  };

  return (
    <>
      <div className="relative h-screen flex overflow-hidden bg-white">
        <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
          <div className="flex-1 relative z-0 flex overflow-hidden">
            <main className="bg-contactDetailsBgColor flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
              <article
                className={`transform top-0 right-0 w-full ${
                  location.pathname !== "/"
                    ? "ease-in-out transition-all duration-300 z-30 translate-x-0"
                    : "-translate-x-full"
                } sm:translate-x-0`}
              >
                <img
                  className="object-cover w-full h-28"
                  src={ContactHeaderImage}
                  alt=""
                />
                <div className="h-8 p-5 absolute top-0 left-0 sm:hidden">
                  <button
                    type="button"
                    className="text-white font-bold hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={handleCloseDrawer}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-8 w-8" aria-hidden="true" />
                  </button>
                </div>
                <ContactMode />
              </article>
            </main>
            <aside
              className={`order-first ${
                location.pathname !== "/" ? "hidden" : "w-full"
              } flex-shrink-0 sm:block sm:w-96 overflow-y-auto border-r border-gray-200`}
            >
              <div className="px-6 pt-6 pb-4">
                <div className="flex flex-row items-center justify-between">
                  <h2 className="text-lg font-medium text-gray-900">
                    All Contacts
                  </h2>
                  <button
                    onClick={toggleAddContactForm}
                    type="button"
                    className="inline-flex items-center button button-add-contact"
                  >
                    <PlusSmIconOutline className="h-6 w-6" aria-hidden="true" />
                    Add Contact
                  </button>
                </div>
                <div className="flex">
                  <div className="flex">
                    <h2 className="text-gray-500">Total: </h2>
                    <h2 className="mx-1">
                      {contactsStore.contactList?.length} Contacts
                    </h2>
                  </div>
                </div>
                <ContactSearchBar />
              </div>
              <ContactList />
            </aside>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(Contact);
