/** Libraries */
import { useHistory } from "react-router";

/** Organisms */
import SubscriptionType from "@organisms/SubscriptionType";

/** Config */
import {
  BASIC_MONTHLY_PRICE,
  BASIC_MONTHLY_PRICE_SKU,
  BASIC_ANNUALLY_PRICE,
  BASIC_ANNUALLY_PRICE_SKU,
} from "@/config";

const Upgrade = () => {
  const history = useHistory();

  const handleGoBack = () => {
    history.go(-1);
  };

  return (
    <div className="relative flex overflow-hidden min-h-screen bg-gray-100">
      <main className="flex-1 py-10 overflow-y-auto">
        <div className="flex justify-between px-10">
          <h1 className="text-2xl text-gray-900">You are in Trial mode with 5 free contacts. To add more contacts UPGRADE NOW!</h1>
          <button
            onClick={handleGoBack}
            className="px-6 py-2 bg-primaryColor text-white text-base font-medium rounded-full hover:bg-primaryColor-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primaryColor-dark"
          >
            Back
          </button>
        </div>

        <div className="space-y-4 md:space-y-0 md:space-x-4 md:flex p-10">
          <div className="flex-grow">
            <SubscriptionType
              amount={BASIC_MONTHLY_PRICE}
              billableTime="Monthly"
              type="month"
              priceId={BASIC_MONTHLY_PRICE_SKU}
            />
          </div>
          <div className="flex-grow">
            <SubscriptionType
              amount={BASIC_ANNUALLY_PRICE}
              billableTime="Annually"
              type="year"
              priceId={BASIC_ANNUALLY_PRICE_SKU}
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default Upgrade;
