/** Libraries */
import YouTube from 'react-youtube';

/** Constants */
import { addFirstContactSteps } from "@constants/onboardingSteps";

const AddContactGuide = () => {
  return (
    <main>
      <div className="max-w-2xl mx-auto px-5">
        <div className="flex px-4 py-5 sm:px-0">
          <div className="bg-white shadow-2xl overflow-hidden flex-1 px-10">
            <div className="space-y-4 sm:space-y-2">
              <div className="flex justify-between align-middle pt-3">
                <h3 className="onboarding-title">Add Contact</h3>
              </div>
              <div className="flex-1 flow-root">
                <div className="flex flex-col items-center pb-5">
                <YouTube
                  videoId="-qxLTZ_vdLc"
                  title="Add First Contact Guide"
                  opts={{
                    width:"500",
                    height:"300"
                  }} />
                  <p className="text-center pt-1">Watch video for more info</p>
                </div>
                <ul role="list">
                  {addFirstContactSteps.map((event) => (
                    <li key={event.id}>
                      <div className="relative pb-3">
                        <div className="relative flex space-x-3">
                          <div>
                            <span className="flex-shrink flex items-center justify-center-0 w-15 h-15 steps-bg">
                              <span className="flex items-center justify-center h-8 w-8 steps shadow-xl">
                                {event.id}
                              </span>
                            </span>
                          </div>
                          <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                            <div>
                              <p className="text-sm text-gray-500">
                                {event.content}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AddContactGuide;
