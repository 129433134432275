/** Types */
import  {IContactDetailElementProps} from '@internal-types/PropTypes';

const ContactDetailElement = ({detailLabel, detailValue}: IContactDetailElementProps) => {
  return (
    <div className="flex-1">
      <dt className="text-sm font-medium text-gray-500">{detailLabel}</dt>
      <dd className="mt-1 text-sm text-gray-900">{detailValue}</dd>
    </div>
  );
};

export default ContactDetailElement;
