import { UserStore } from '@store/UserStore';
import { ContactsStore } from '@store/ContactsStore';
import { CategoryStore } from './CategoryStore';
import { OnboardingStore } from './OnboardingStore';

export class RootStore {
    userStore: UserStore;
    contactsStore: ContactsStore;
    categoryStore: CategoryStore;
    onboardingStore: OnboardingStore;

    constructor() {
        this.userStore = new UserStore(this);
        this.contactsStore = new ContactsStore(this);
        this.categoryStore = new CategoryStore(this);
        this.onboardingStore = new OnboardingStore(this);
    }
}