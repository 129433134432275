import Axios from 'axios';

/** Config */
import { DOMAIN } from '@/config';
import { IAddContact } from '@internal-types/AddContactsTypes';

export const listContacts = () => {
  const token = localStorage.getItem('token');
  const options: any = {
    method: 'post',
    headers: {
      'Authorization' : `Bearer ${token}`,
    },
    url: `${DOMAIN!}/api/v1/contacts/list/all`,
  };
  return Axios(options);
};

export const saveContact = (data: {'contact': IAddContact}) => {
  const token = localStorage.getItem('token');
  const options: any = {
    method: 'post',
    headers: {
      'Authorization' : `Bearer ${token}`,
    },
    data: data,
    url: `${DOMAIN!}/api/v1/contacts/save`,
  };

  return Axios(options);
};

export const deleteContact = (id:string) => {
  const token = localStorage.getItem('token');
  const options: any = {
    method: 'post',
    headers: {
      'Authorization' : `Bearer ${token}`,
    },
    url: `${DOMAIN!}/api/v1/contacts/delete/${id}`,
  };
  return Axios(options);
};

export const updateContact = (data: {'contact': IAddContact}) => {
  const token = localStorage.getItem('token');
  const options: any = {
    method: 'post',
    headers: {
      'Authorization' : `Bearer ${token}`,
    },
    data: data,
    url: `${DOMAIN!}/api/v1/contacts/update`,
  };

  return Axios(options);
};
