/** Libraries */
import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const ReRoute = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(()=>{
	loginWithRedirect({action: 'signUp', redirectUri: window.location.origin});
  },[]);

  return (
    <>Redirecting...</>
  );
};

export default ReRoute;
