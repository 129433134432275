/** Libraries */
import { makeAutoObservable } from "mobx";
import { RootStore } from "@store/RootStore";

/** Types */
import { IContact } from "@internal-types/ContactsTypes";

export interface IContactStore {
  contactList: Array<IContact>;
  filteredContactList: Array<IContact>;
  filterValue: string;
  selectedContact: IContact;
  showDeleteContactDialog: boolean;
  showContactDetailsDrawer: boolean;
  mode: string;
  categories: Array<boolean>;
  selectedCategories: Array<string>;
  isFormDirty: boolean;
}

export class ContactsStore implements IContactStore {
  private rootStore: RootStore;

  contactList: Array<IContact> = [];
  filteredContactList: Array<IContact> = [];
  filterValue = "";
  selectedContact: IContact = { name: "", profileUrl: "" };
  showDeleteContactDialog = false;
  showContactDetailsDrawer = false;
  mode = "";
  categories = [false, false, false, false, false, false, false, false];
  selectedCategories: Array<string> = ["", "", "", "", "", "", "", ""];
  isFormDirty = false;
  contactLimit = 100;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  setContactList(contacts: Array<IContact>) {
    this.contactList = contacts;
    this.filteredContactList = [...contacts];
  }

  setFilterValue(filter: string) {
    this.filterValue = filter;
  }

  setFilteredContactList(filteredContacts: Array<IContact>) {
    this.filteredContactList = filteredContacts;
  }

  setSelectedContact(contact: IContact) {
    this.selectedContact = contact;
  }

  toggleDeleteContactDialog(toggle: boolean) {
    this.showDeleteContactDialog = toggle;
  }

  toggleContactDetailsDrawer(toggle: boolean) {
    this.showContactDetailsDrawer = toggle;
  }

  toggleIsFormDirty(toggle: boolean) {
    this.isFormDirty = toggle;
  }

  setMode(mode: string) {
    this.mode = mode;
  }

  setCategoryValue(index: number, value: boolean) {
    this.categories[index] = value;
  }

  setCategories(categories: Array<boolean>) {
    this.categories = [...categories];
  }

  setSelectedCategories(
    selectedCategory: string,
    index: number,
    isChecked: boolean
  ) {
    if (this.selectedCategories[index]) {
      this.selectedCategories[index] = isChecked ? selectedCategory : "";
    }
  }

  resetCategories() {
    this.categories = [false, false, false, false, false, false, false, false];
    this.selectedCategories = [];
  }

  resetFilteredContactList() {
    this.filteredContactList = [...this.contactList];
  }

  selectFirstContact() {
    this.selectedContact = this.filteredContactList.length
      ? this.filteredContactList[0]
      : ({} as IContact);
  }
}
