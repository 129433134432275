/** Types */
import { IFrequency } from '@internal-types/CategoryTypes';

export const Frequency: IFrequency[] = [
  { id: 0, name: 'Never' },
  { id: 1, name: 'Weekly' },
  { id: 2, name: 'Bi-Weekly' },
  { id: 3, name: 'Once a month' },
  { id: 4, name: 'Once every two months' },
  { id: 5, name: 'Once every three months' },
  { id: 6, name: 'Once every six months' },
  { id: 7, name: 'Once every nine months' },
  { id: 8, name: 'Once a year' },
];