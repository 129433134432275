/** Libraries */
import { MenuIcon } from "@heroicons/react/solid";
import YouTube from 'react-youtube';

/** Constants */
import { INSTALLATION_STEPS } from "@constants/onboardingSteps";

const BookmarkletInstallationGuide = () => {
  return (
    <main>
      <div className="max-w-2xl mx-auto px-5">
        <div className="flex px-4 py-5 sm:px-0">
          <div className="bg-white shadow-2xl overflow-hidden flex-1 px-10">
            <div className="space-y-4 sm:space-y-2">
              <div className="pt-3">
                <h3 className="onboarding-title">Install Bookmarklet</h3>
                <p className="px-5 text-center text-sm">
                  With one click, the “bookmarklet” creates a contact in MyVital
                  Few. You can capture contacts from any social media site
                  including LinkedIn and Facebook. The video shows you how to
                  download the bookmarklet.
                </p>
              </div>
              <div className="flex-1 flow-root max-h-xl">
                <div className="flex flex-col items-center pb-5">
                <YouTube
                  videoId="sc4H-9MasZY"
                  title="Bookmarklet Installation Guid"
                  opts={{
                    width:"500",
                    height:"300"
                  }} />
                  <p className="text-center pt-1">Watch video for more info</p>
                </div>
                <ul role="list">
                  {INSTALLATION_STEPS.map((event) => (
                    <li key={event.id}>
                      <div className="relative pb-3">
                        <div className="relative flex space-x-3">
                          <div>
                            <span className="flex-shrink flex items-center justify-center-0 w-15 h-15 steps-bg">
                              <span className="flex items-center justify-center h-8 w-8 steps shadow-xl">
                                {event.id}
                              </span>
                            </span>
                          </div>
                          <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                            <div className="flex">
                              <p className="text-sm text-gray-500">
                                {event.content}
                              </p>

                              {event.id === 2 ? (
                                <a
                                  href={event.href}
                                  className="ml-2 inline-flex font-medium text-gray-900 hover:text-primaryColor"
                                >
                                  <MenuIcon
                                    className="-ml-1 mr-1 h-5 w-5 text-primaryColor"
                                    aria-hidden="true"
                                  />
                                  {event.action}
                                </a>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default BookmarkletInstallationGuide;
