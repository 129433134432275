/** Types */
import { IContact } from "@internal-types/ContactsTypes";
import { ICategory } from "@internal-types/CategoryTypes";

/** Constants */
import { Frequency } from "@constants/frequency";
import duration from "dayjs/plugin/duration";
import dayjs from "dayjs";

export const addImageURL = (contactList: Array<IContact>) => {
  contactList.forEach((contact) => {
    contact.imageUrl = `https://ui-avatars.com/api/?name=${contact.name}`;
  });
};

export const transformCategoryOptions = (categoryList: ICategory[]) => {
  const options: Array<{ value: string; label: string }> = [];

  categoryList.forEach((category) => {
    options.push({ label: category.name, value: category.id });
  });

  return options;
};

export const transformFrequencyOptions = () => {
  const options: Array<{ value: number; label: string }> = [];

  Frequency.forEach((frequency) => {
    options.push({ label: frequency.name, value: frequency.id });
  });

  return options;
};

export const getNextContactDate = (schedule: number) => {
  dayjs.extend(duration);
  let next_contact_date = dayjs();
  const currentDate = dayjs().valueOf();

  switch (schedule) {
    case 0:
      // never
      next_contact_date = dayjs(currentDate).add(
        dayjs.duration({
          years: 100,
        })
      );
      break;
    case 1:
      // weekly
      next_contact_date = dayjs(currentDate).add(
        dayjs.duration({
          weeks: 1,
        })
      );
      break;
    case 2:
      // bi-weekly
      next_contact_date = dayjs(currentDate).add(
        dayjs.duration({
          milliseconds: 0,
          seconds: 60,
          minutes: 60,
          hours: 1,
          days: 1,
          weeks: 2,
        })
      );
      break;
    case 3:
      // once a month
      next_contact_date = dayjs(currentDate).add(
        dayjs.duration({
          months: 1,
        })
      );
      break;
    case 4:
      // once every two months
      next_contact_date = dayjs(currentDate).add(
        dayjs.duration({
          months: 2,
        })
      );
      break;
    case 5:
      // once every three months
      next_contact_date = dayjs(currentDate).add(
        dayjs.duration({
          months: 3,
        })
      );
      break;
    case 6:
      // once every six months
      next_contact_date = dayjs(currentDate).add(
        dayjs.duration({
          months: 6,
        })
      );
      break;
    case 7:
      // once every nine months
      next_contact_date = dayjs(currentDate).add(
        dayjs.duration({
          months: 9,
        })
      );
      break;
    case 8:
      // once a year
      next_contact_date = dayjs(currentDate).add(
        dayjs.duration({
          years: 1,
        })
      );
      break;
    default:
      // TO DO
      console.log("Next contact date is invalid");
      break;
  }

  return next_contact_date?.valueOf();
};
