/** Libraries */
import { ReactNode, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  UserCircleIcon,
  ShareIcon,
  BookmarkIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { observer } from "mobx-react-lite";
import { ToastContainer } from "react-toastify";
import dayjs from "dayjs";

/** Store */
import { useStores } from "@hooks/useStores";

/** Molecules */
import UpgradeBanner from "@molecules/UpgradeBanner";

/** Atoms */
import ShareModal from "@atoms/ShareModal";

/** Assets */
import MVFLogo from "@assets/mvf-logo.png";

type ILayoutProps = {
  children: ReactNode;
};

declare var window: any;

const Layout = ({ children }: ILayoutProps) => {
  const { userStore } = useStores();
  const { logout, user } = useAuth0();
  const [dropdown, setDropdown] = useState(false);
  const [menu, setMenu] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (
      !userStore.metadata.subscription ||
      userStore.metadata.subscription.cancel_at_period_end ||
      userStore.metadata.subscription?.current_period_end === dayjs().unix()
    ) {
      userStore.toggleUpgradeBanner(false); //was true
    }
  }, [userStore.metadata.subscription]);

  const endSession = () => {
    logout({ returnTo: window.location.origin });
  };

  const toggleDropdown = () => {
    setDropdown(!dropdown);
  };
  const toggleMenu = () => {
    setMenu(!menu);
  };

  const handleGoToSettings = () => {
    setDropdown(!dropdown);
    if (history.location.pathname === "/settings") return;

    history.push("/settings");
  };

  const handleGoToHelp = () => {
    setDropdown(!dropdown);
    if (history.location.pathname === "/help") return;

    history.push("/help");
  };

  const toggleShare = () => {
    userStore.toggleShareModal(true);
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="h-screen flex flex-col overflow-hidden bg-white">
        {userStore.showUpgradeBanner && <UpgradeBanner />}
        <ShareModal />
        <div className="h-full flex overflow-hidden bg-white">
          <div
            className={`fixed inset-0 flex z-40 lg:hidden ${
              menu ? "" : "hidden"
            }`}
            role="dialog"
            aria-modal="true"
          >
            <div
              className={`fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity ease-linear duration-300 opacity-${
                menu ? "100" : "0"
              }`}
              aria-hidden="true"
            ></div>
            <div
              className={`relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white transition ease-in-out duration-300 transform ${
                menu ? "translate-x-0" : "-translate-x-full"
              }`}
            >
              <div
                className={`absolute top-0 right-0 -mr-12 pt-2 ease-in-out duration-300 opacity-${
                  menu ? "100" : "0"
                }`}
              >
                <button
                  onClick={toggleMenu}
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                >
                  <span className="sr-only">Close sidebar</span>
                  <svg
                    className="h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <div className="flex-shrink-0 flex items-center px-4">
                <img className="h-10 w-auto" src={MVFLogo} alt="Fitzdrake" />
              </div>
              <div className="mt-5 flex-1 flex h-0 overflow-y-auto">
                <nav className="flex-1 flex flex-col px-2">
                  <div className="flex-1 space-y-1 flex flex-col justify-between">
                    <Link
                      to="/"
                      onClick={() => toggleMenu()}
                      className="text-gray-600 hover:text-gray-900 hover:bg-gray-50 group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md"
                    >
                      <UserCircleIcon
                        className="-ml-1 mr-2 h-7 w-7 text-gray"
                        aria-hidden="true"
                      />
                      Contacts
                    </Link>

                    {/* Sidebar */}
                    <div
                      dangerouslySetInnerHTML={window.sidebarContent}
                      id="sidebarContainer"
                      className="h-full bg-gray-100 space-y-2 py-3 px-2"
                    >
                      {/* <div className="bg-white px-2 py-1">
                        <h4 className="mb-1 text-lg font-semibold text-center">
                          Lorem ipsum dolor.
                        </h4>
                        <p className="leading-5">
                          Lorem ipsum dolor, sit amet consectetur adipisicing
                          elit. Velit, aliquid!
                        </p>
                      </div>
                      <div className="bg-white px-2 py-1">
                        <h4 className="mb-1 text-lg font-semibold text-center">
                          Lorem ipsum dolor.
                        </h4>
                        <p className="leading-5">
                          Lorem ipsum dolor, sit amet consectetur adipisicing
                          elit. Velit, aliquid!
                        </p>
                      </div> */}
                    </div>

                    <Link
                      to="/help"
                      onClick={() => toggleMenu()}
                      className="text-gray-600 hover:text-gray-900 hover:bg-gray-50 group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md"
                    >
                      <BookmarkIcon
                        className="-ml-1 mr-2 h-7 w-7 text-gray"
                        aria-hidden="true"
                      />
                      Install Bookmarklet
                    </Link>
                  </div>
                  <div className="flex-shrink-0">
                    <button
                      className="w-full text-gray-600 hover:text-gray-900 hover:bg-gray-50 group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md"
                      onClick={toggleShare}
                    >
                      <ShareIcon
                        className="-ml-1 mr-2 h-7 w-7 text-gray"
                        aria-hidden="true"
                      />
                      Share My Vital Few&#8482;
                    </button>
                  </div>
                </nav>
              </div>
            </div>

            <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
          </div>
          <div className="hidden lg:flex lg:flex-shrink-0">
            <div className="flex flex-col w-64 border-r border-gray-200 pt-5 bg-white">
              <div className="flex items-center flex-shrink-0 px-6 py-0.5">
                <img className="h-10 w-auto" src={MVFLogo} alt="Fitzdrake" />
              </div>
              <div className="h-0 flex-1 flex flex-col overflow-y-auto border-t border-gray-200">
                <div className="px-3 mt-6 relative inline-block text-left">
                  <div>
                    <span className="flex w-full justify-between items-center">
                      <span className="flex min-w-0 items-center justify-between space-x-3">
                        <img
                          className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
                          src={user?.picture}
                          alt=""
                        />
                        <span className="flex-1 flex flex-col min-w-0">
                          <span className="text-gray-900 text-sm font-medium truncate">
                            {userStore?.fullName}
                          </span>
                          <span className="text-gray-500 text-sm truncate">
                            {userStore.contactInfo.email}
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                </div>

                <nav className="flex-1 flex flex-col px-3 mt-12">
                  <div className="flex-1 space-y-1 flex flex-col justify-between">
                    <Link
                      className="text-gray-600 hover:text-gray-900 hover:bg-gray-50 group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md"
                      to="/"
                    >
                      <UserCircleIcon
                        className="-ml-1 mr-2 h-7 w-7 text-gray"
                        aria-hidden="true"
                      />
                      Contacts
                    </Link>

                    {/* Sidebar */}
                    <div
                      dangerouslySetInnerHTML={window.sidebarContent}
                      id="sidebarContainer"
                      className="h-full bg-gray-100 space-y-2 py-3 px-2"
                    >
                      {/* <div className="bg-white px-2 py-1">
                        <h4 className="mb-1 text-lg font-semibold text-center">
                          Lorem ipsum dolor.
                        </h4>
                        <p className="leading-5">
                          Lorem ipsum dolor, sit amet consectetur adipisicing
                          elit. Velit, aliquid!
                        </p>
                      </div>
                      <div className="bg-white px-2 py-1">
                        <h4 className="mb-1 text-lg font-semibold text-center">
                          Lorem ipsum dolor.
                        </h4>
                        <p className="leading-5">
                          Lorem ipsum dolor, sit amet consectetur adipisicing
                          elit. Velit, aliquid!
                        </p>
                      </div> */}
                    </div>

                    <Link
                      to="/help"
                      onClick={() => toggleMenu()}
                      className="text-gray-600 hover:text-gray-900 hover:bg-gray-50 group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md"
                    >
                      <BookmarkIcon
                        className="-ml-1 mr-2 h-7 w-7 text-gray"
                        aria-hidden="true"
                      />
                      Install Bookmarklet
                    </Link>
                  </div>
                  <div className="flex-shrink-0">
                    <button
                      className="w-full text-gray-600 hover:text-gray-900 hover:bg-gray-50 group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md"
                      onClick={toggleShare}
                    >
                      <ShareIcon
                        className="-ml-1 mr-2 h-7 w-7 text-gray"
                        aria-hidden="true"
                      />
                      Share My Vital Few&#8482;
                    </button>
                  </div>
                </nav>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-0 flex-1 overflow-hidden">
            <div className="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200">
              <button
                onClick={toggleMenu}
                className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primaryColor lg:hidden"
              >
                <span className="sr-only">Open sidebar</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h8m-8 6h16"
                  />
                </svg>
              </button>
              <div className="flex-1 flex justify-end px-4 sm:px-6 lg:px-8">
                <div className="flex items-center">
                  <div className="ml-3 relative">
                    <div>
                      <button
                        onClick={toggleDropdown}
                        type="button"
                        className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primaryColor"
                        id="user-menu"
                        aria-expanded="false"
                        aria-haspopup="true"
                      >
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src={user?.picture}
                          alt=""
                        />
                        <ChevronDownIcon
                          className="-mr-1 ml-2 h-5 w-5"
                          aria-hidden="true"
                        />
                      </button>
                    </div>

                    <div
                      className={`origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none transition ease-${
                        dropdown ? "out" : "in"
                      } duration-${dropdown ? "100" : "75"} transform opacity-${
                        dropdown ? "100" : "0"
                      } scale-${dropdown ? "100" : "95"}`}
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu"
                    >
                      {dropdown && (
                        <>
                          <div className="py-1" role="none">
                            <button
                              onClick={handleGoToSettings}
                              className="w-full block px-4 py-2 text-sm text-gray-700 text-left hover:bg-gray-100 hover:text-gray-900"
                              role="menuitem"
                            >
                              Profile
                            </button>
                          </div>
                          <div className="py-1" role="none">
                            <button
                              onClick={handleGoToHelp}
                              className="w-full block px-4 py-2 text-sm text-gray-700 text-left hover:bg-gray-100 hover:text-gray-900"
                              role="menuitem"
                            >
                              Help/FAQ
                            </button>
                          </div>
                          <div className="py-1" role="none">
                            <button
                              onClick={endSession}
                              className="w-full block px-4 py-2 text-sm text-gray-700 text-left hover:bg-gray-100 hover:text-gray-900"
                              role="menuitem"
                            >
                              Logout
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {children}
          </div>
        </div>
        <footer className="flex justify-center">
          <p className="text-xs m-1">
            Copyright &copy; My Vital Few&#8482;. All rights reserved.{" "}
            <button
              onClick={() => history.push("/termsandconditions")}
              className="text-xs text-primaryColor hover:text-primaryColor-dark"
            >
              Terms and Conditions
            </button>{" "}
            and{" "}
            <button
              onClick={() => history.push("/privacypolicy")}
              className="text-xs text-primaryColor hover:text-primaryColor-dark"
            >
              Privacy Policy
            </button>
          </p>
        </footer>
      </div>
    </>
  );
};

export default observer(Layout);
