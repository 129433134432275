/** Libraries  */
import React, { useEffect, useState } from "react";
import { FilterIcon, SearchIcon } from "@heroicons/react/solid";
import { Dropdown, Menu } from "antd";
import { observer } from "mobx-react-lite";

/** Store */
import { useStores } from "@hooks/useStores";
import { IContact } from "@internal-types/ContactsTypes";
import dayjs from "dayjs";

const ALL_CONTACTS = 99;

const ContactSearchBar = () => {
  const { categoryStore, contactsStore } = useStores();
  const [selectedCategoryIndex, setSelectedCategoryIndex] =
    useState<number>(ALL_CONTACTS);
  const [searchText, setSearchText] = useState<string>("");

  const handleCategoryChange = ({ key }: { key: React.Key }) => {
    let categoryIndex = parseInt(key as string);
    setSelectedCategoryIndex(categoryIndex);
    if (categoryIndex !== ALL_CONTACTS) {
      const category = categoryStore.categories[categoryIndex];
      contactsStore.setFilterValue(category);
    } else {
      contactsStore.setFilterValue("");
    }
  };

  const handleSearchChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearchText(value);
  };

  useEffect(() => {
    const searchResults: Array<IContact> = contactsStore.contactList.filter(
      (contact) => {
        let excluded = false;

        if (selectedCategoryIndex !== ALL_CONTACTS) {
          // category[index] will be true of the contact is in that category,
          // so we exclude the contact from the results if they are NOT in that category
          if (!contact.metadata?.category) excluded = true;
          else {
            if (!contact.metadata.category[selectedCategoryIndex])
              excluded = true;
          }
        }

        if (searchText !== "") {
          let nameMatch = contact.name
            .toLowerCase()
            .includes(searchText.toLowerCase());
          let companyMatch = contact.company
            ?.toLowerCase()
            .includes(searchText.toLowerCase());
          // we use both... for backwards compatability
          let notesMatch = !!contact.metadata?.notes
            ?.toLowerCase()
            .includes(searchText.toLowerCase());
          let plaintextNotesMatch = !!contact.metadata?.plaintextNotes
            ?.toLowerCase()
            .includes(searchText.toLowerCase());
          // excluded if no field matches
          if (
            !nameMatch &&
            !companyMatch &&
            !notesMatch &&
            !plaintextNotesMatch
          )
            excluded = true;
        }

        // Include contacts who's next_contact field has lapsed. (People needed to contact)
        if (selectedCategoryIndex === 89) {
          if (dayjs().isSame(dayjs(contact?.metadata?.next_contact), 'day')) {
            excluded = false
          }
        }

        return !excluded;
      }
    );
    contactsStore.setFilteredContactList(searchResults);
    contactsStore.selectFirstContact();
  }, [contactsStore, searchText, selectedCategoryIndex]);

  const categoryMenu = (
    <Menu
      onClick={handleCategoryChange}
      selectedKeys={[`${selectedCategoryIndex}`]}
    >
      <Menu.Item key={99}>All Contacts</Menu.Item>
      {categoryStore.categories?.map((value, index) => (
        <Menu.Item key={index}>{value}</Menu.Item>
      ))}
      <hr className="divide-inherit" />
      <Menu.Item className="flex items-center" key={89}>
        <div className="rounded-full w-2 h-2 bg-red-600 mr-2"></div>
        Need to contact
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="mt-3 flex flex-col">
      <div className="flex-1 flex space-x-4">
        <div className="flex-1">
          <div className="flex-1 min-w-0">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="search"
                name="search"
                id="search"
                className="focus:ring-primaryColor focus:border-primaryColor block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                placeholder="Search Contacts"
                value={searchText}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>
        <div className="flex-shrink-0 justify-center button-filter">
          <Dropdown
            overlay={categoryMenu}
            trigger={["click"]}
            placement="bottomCenter"
            arrow
          >
            <FilterIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default observer(ContactSearchBar);
